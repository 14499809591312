export const ServiceSlide = [
	{
		image: '',
		text: ``,
	},

	{
		image: '/images/picture/service_slide01_m.png',
		text: `The first avatar coordinating P2E
		service on the market, Meta Livly 
		uses a token based economy and 
		implements NFTs 
		using blockchain technology.`,
	},
	{
		image: '/images/picture/service_slide02_m.png',
		text: `Select from a wide variety of livlies to
		take care of and welcome them
		into your digital family.
		
		Livlies can be transformed into
		different species later on.`,
	},
	{
		image: '/images/picture/service_slide03_m.png',
		text: `Livlies change colors according to
		what kind of bug they're fed,
		so try experimenting to customize
		your livly's color!`,
	},
	{
		image: '/images/picture/service_slide04_m.png',
		text: `When livlies are well taken care of,
		they'll excrete jewels called doodoo.`,
	},
	{
		image: '/images/picture/service_slide05_m.png',
		text: `Exchange your livly's doodoo for GP
		to spin gachas and gain unique items.`,
	},
	{
		image: '/images/picture/service_slide06_m.png',
		text: `Customize your island and your hom's
		fashion  with the various items
		that you get from gachas.`,
	},
	{
		image: '/images/picture/service_slide07_m.png',
		text: `The more fashion and island decoration items
		you get, the more Starry Dust (SD)
		you'll be able to collect. 
		SD is a currency used specifically 
		in Meta Livly, but can be exported 
		to your wallet to cash into other currencies.`,
	},
	{
		image: '/images/picture/service_slide08_m.png',
		text: `Visit other islands to make friends,
		water trees, and enjoy a relaxing walk with
		your livly!`,
	},
];
