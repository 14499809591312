import styled from '@emotion/styled';

import { InnerWrap } from '@/assets/styles/Common.styles';

export const FooterWrap = styled.footer`
	width: 100%;
	background: #790d99;
`;

export const Inner = styled(InnerWrap)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 325px;
`;

export const LinkBlock = styled.div`
	text-align: right;
	span {
		display: block;
		font-size: 18px;
		color: #fff;
		font-weight: bold;
		margin-bottom: 20px;
	}
	a {
		width: 56px;
		height: 55px;
		display: inline-block;
		& + a {
			margin-left: 27px;
		}
	}
`;

export const Image = styled.img`
	width: 410px;
	height: 99px;
`;

export const Copyright = styled.p`
	margin-top: 20px;
	font-size: 18px;
	font-weight: 100;
	color: #fff;
`;
