import React from 'react';

import styled from '@emotion/styled';

import Header from '@/gachaWeb/common/Header/Header';
import Intro from '@/gachaWeb/containers/Intro/Intro';

const Main = styled.main`
	height: 100%;
	background: #000;
`;

const GachaWeb: React.VFC = () => {
	return (
		<Main>
			<Header />
			<Intro />
		</Main>
	);
};

export default GachaWeb;
