import styled from '@emotion/styled';

export const Wrapper = styled.footer`
	position: fixed;
	bottom: 0;
	display: flex;
	justify-content: space-around;
	width: 100%;
	bottom: 0;
	padding: 31px 0;
	background: #000;
	color: #fff;
	z-index: 1;

	section {
		display: flex;
		justify-content: space-between;
		width: 1450px;

		> div {
			display: flex;
			align-items: center;

			> span {
				font-size: 25px;
				margin-right: 67px;
			}
			ul {
				display: flex;
				li {
				}
			}
		}
	}
`;

export const SnsWrapper = styled.ul`
	li {
		margin-right: 36px;
		a {
			display: inline-block;
			width: 60px;
			cursor: pointer;
			transition: opacity 0.5s;

			&:hover {
				transform: scale(1.05);
			}
		}

		&:last-child {
			margin: 0;
		}
	}
`;

export const AppWrapper = styled.ul`
	display: flex;

	li {
		border: 1px solid #fff;
		border-radius: 8px;
		margin-right: 64px;
		padding: 6px 50px;
		.app-icon-text {
			opacity: 1;
			transition: opacity 0.5s ease-in-out;
		}

		.app-icon-image {
			transform: translate(0px);
			transition: all 0.5s ease-in-out;
		}

		&:hover {
			transition: all 0.2s;

			.app-icon-text {
				opacity: 0;
			}

			.app-icon-image {
				transform: translate(110px);
			}
		}

		img {
			vertical-align: -webkit-baseline-middle;
			&:first-of-type {
				margin-right: 15px;
			}
		}
		&:last-child {
			margin: 0;
		}
	}
`;
