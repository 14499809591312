import React, { ReactNode } from 'react';

import ReactModal from 'react-modal';

interface IProps {
	isModalOpen: boolean;
	width: number;
	height: number;
	children: ReactNode;
	handleCloseModal: () => void;
	overlay?: string;
}

const EventModal: React.FC<IProps> = ({
	isModalOpen,
	width,
	children,
	height,
	handleCloseModal,
	overlay,
}) => {
	return (
		<ReactModal
			isOpen={isModalOpen}
			onRequestClose={handleCloseModal}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: overlay ? overlay : 'rgba(0, 0, 0, 0.3)',
					zIndex: '10000',
				},
				content: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%,-50%)',
					width: `${width}vmin`,
					height: `${height}vmin`,
					overflow: 'hidden',
					padding: 0,
					backgroundColor: 'white',
					borderRadius: '21px',
					border: 'none',
				},
			}}
			ariaHideApp={false}
		>
			{children}
		</ReactModal>
	);
};

export default EventModal;
