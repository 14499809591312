import React, { useState } from 'react';

import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { RewardKey, columnHelper } from './RewardSlice';
import { Wrapper } from './RewardTable.styles';

interface IProps {
	tableData: RewardKey[];
}

const columns = [
	columnHelper.accessor('rewardTier', {
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor('participantsNumber', {
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor('distribution', {
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor('minSD', {
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor('minGP', {
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor('maxGP', {
		cell: (info) => info.getValue(),
	}),
];

const Table: React.FC<IProps> = ({ tableData }) => {
	const [data] = useState(() => [...tableData]);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Wrapper>
			<thead>
				<tr>
					<th>Reward tier</th>
					<th>
						Number of
						<br /> participants
					</th>
					<th>
						Distribution rate
						<br />
						<span>&#40; same for SD, GP&#41; </span>
					</th>
					<th>
						Minimum SD <br />
						rewards
					</th>
					<th>
						Minimum GP <br />
						rewards
					</th>
					<th>
						Maximum GP <br />
						rewards
					</th>
				</tr>
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</Wrapper>
	);
};

export default Table;
