import { css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import './font.css';

export const styles = css`
	${emotionNormalize}

	* {
		font-family: 'Spoqa Han Sans Neo', 'sans-serif';
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		border: 0;
	}

	html {
		height: 100%;
		background: #3c3c3c;
		overflow-x: hidden;
	}

	body {
		height: 100%;
		position: relative;
		overflow-x: hidden;
	}
	button {
		background: transparent;
		cursor: pointer;
	}
	ul {
		list-style: none;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	img {
		max-width: 100%;
	}

	#root {
		height: 100%;
	}
`;
