import { createColumnHelper } from '@tanstack/react-table';

export interface RewardKey {
	rewardTier: string;
	participantsNumber: number;
	distribution: string;
	minSD: number;
	minGP: number;
	maxGP: number;
}

export const RewardDataSlice: RewardKey[] = [
	{
		rewardTier: 'Top1',
		participantsNumber: 1,
		distribution: '2.40%',
		minSD: 20,
		minGP: 1000,
		maxGP: 2000,
	},
	{
		rewardTier: 'Top2',
		participantsNumber: 1,
		distribution: '2.28%',
		minSD: 15,
		minGP: 850,
		maxGP: 1900,
	},
	{
		rewardTier: 'Top3',
		participantsNumber: 1,
		distribution: '2.20%',
		minSD: 12,
		minGP: 725,
		maxGP: 1800,
	},
	{
		rewardTier: 'Top4',
		participantsNumber: 1,
		distribution: '2.13%',
		minSD: 10,
		minGP: 600,
		maxGP: 1700,
	},
	{
		rewardTier: 'Top5',
		participantsNumber: 1,
		distribution: '2.04%',
		minSD: 7.5,
		minGP: 500,
		maxGP: 1500,
	},
	{
		rewardTier: 'Top6',
		participantsNumber: 1,
		distribution: '1.77%',
		minSD: 5,
		minGP: 350,
		maxGP: 1450,
	},
	{
		rewardTier: 'Top7',
		participantsNumber: 1,
		distribution: '1.69%',
		minSD: 5,
		minGP: 325,
		maxGP: 1400,
	},
	{
		rewardTier: 'Top8',
		participantsNumber: 1,
		distribution: '1.62%',
		minSD: 5,
		minGP: 300,
		maxGP: 1350,
	},
	{
		rewardTier: 'Top9',
		participantsNumber: 1,
		distribution: '1.57%',
		minSD: 5,
		minGP: 275,
		maxGP: 1300,
	},
	{
		rewardTier: 'Top10',
		participantsNumber: 1,
		distribution: '1.54%',
		minSD: 5,
		minGP: 250,
		maxGP: 1200,
	},
	{
		rewardTier: 'Top11 ~ 20',
		participantsNumber: 10,
		distribution: '13.20%',
		minSD: 3,
		minGP: 100,
		maxGP: 1000,
	},
	{
		rewardTier: 'Top21 ~ 50',
		participantsNumber: 30,
		distribution: '26.40%',
		minSD: 2,
		minGP: 50,
		maxGP: 800,
	},
	{
		rewardTier: 'Top51 ~ 300',
		participantsNumber: 250,
		distribution: '41.16%',
		minSD: 0.02,
		minGP: 5,
		maxGP: 200,
	},
];

export const columnHelper = createColumnHelper<RewardKey>();
