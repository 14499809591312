import styled from '@emotion/styled';

import { InnerWrap, SwiperWrapLg } from '@/assets/styles/Common.styles';

export const Inner = styled(InnerWrap)`
	width: 82vw;
	height: auto;
`;

export const SwiperWrapper = styled(SwiperWrapLg)`
	text-align: center;
	background: url('/images/picture/service_m.png') no-repeat;
	background-size: 100% 100%;
	background-position: 100% 100%;

	.swiper-container {
		padding: 50px 0;
	}

	.swiper-pagination {
		width: 100%;
		bottom: 18px;
		.swiper-pagination-bullet {
			width: 18px;
			height: 17px;
			background-size: contain;
			&.swiper-pagination-bullet {
				background-size: contain;
			}
		}
	}
`;

export const Contents = styled.p`
	font-size: 12px;
	color: #fff;
	line-height: 18px;
	white-space: pre-line;

	&.img-slide-scroll {
		img {
			width: 58px;
			height: 50px;
			margin-top: 33px;
			margin-bottom: 49px;
		}
	}
`;

export const Video = styled.div`
	margin: 0 auto;
	position: relative;
	width: 191px;
	height: 352px;
	// background: url('/images/picture/service_mobile_bg.png') no-repeat;
	// background-size: cover;

	video {
		object-fit: contain;
		position: absolute;
		top: -124px;
		right: -2px;
		width: 191px;
		height: 352px;
	}
`;

export const Title = styled.h2`
	color: #3dffc6;
	font-size: 28px;
	margin-bottom: 28px;
`;

export const SlideImg = styled.img`
	max-width: 85%;
`;

export const PrevButton = styled.button`
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	z-index: 100;
`;

export const NextButton = styled(PrevButton)`
	left: auto;
	right: 10px;
	z-index: 100;
`;
