import styled from '@emotion/styled';

export const Wrapper = styled.header`
	display: flex;
	justify-content: space-around;
	width: 100%;
	background-color: #000;
	color: #fff;

	section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 1450px;

		h1 {
			font-weight: bold;
			font-size: 25px;
		}
	}
`;

export const Nav = styled.nav`
	> ul {
		display: flex;

		> li {
			margin-right: 50px;
			color: #fff;
			cursor: pointer;

			.material-symbols-outlined {
				margin-left: 10px;
				vertical-align: middle;
				transform: rotate(180deg);
				font-size: 18px;
			}

			&:last-child {
				margin: 0;
			}

			button {
				color: #fff;
			}

			&.disable {
				color: #fff;
				opacity: 0.3;
			}
		}
	}
`;

export const DropDownWrapper = styled.li`
	position: relative;

	.header-dropDown {
		height: 0;
		overflow: hidden;
	}
	&:hover .header-dropDown {
		height: 110px;
	}
`;

export const DropDown = styled.ul`
	position: absolute;
	top: 41px;
	width: 100%;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	overflow: hidden;
	background: #fff;
	transition: all 0.3s;
	z-index: 4;

	li {
		a {
			display: block;
			padding: 17px;
			font-size: 17px;
			font-weight: bold;
			color: #000;

			&:hover {
				color: #7dccba;
				transition: all 0.2s;
			}
		}
	}
`;
