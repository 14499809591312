import React, { useEffect, useState } from 'react';

import {
	SnsLinkWrap,
	SnsAnchor,
	SnsImg,
	// BubbleBlock
} from './SnsLink.styles';

const SnsLink: React.VFC = () => {
	// const [isBubble, setIsBubble] = useState(true);
	const [scrollY, setScrollY] = useState(0);

	const handleScroll = () => {
		setScrollY(window.pageYOffset);
		if (scrollY > 0) {
			// setIsBubble(false);
		} else {
			// setIsBubble(true);
		}
	};

	useEffect(() => {
		const watch = () => {
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('mousemove', handleScroll);
		};
		watch();
		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('mousemove', handleScroll);
		};
	});

	return (
		<SnsLinkWrap onScroll={handleScroll}>
			{/* {isBubble && (
				<BubbleBlock>
					<img
						src="/images/common/bubble.png"
						className="bubble"
						width={98}
						height={20}
						alt="metalivly"
					/>

					<p className="text">Ongoing Events</p>
				</BubbleBlock>
			)} */}
			<div>
				<li>
					<SnsAnchor
						href="https://linktr.ee/metalivly"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_linktree.png"
							width={31}
							height={31}
							alt="linktree"
						/>
					</SnsAnchor>
				</li>
				<li>
					<SnsAnchor
						href="https://cocone.app/dHQ"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_discord.png"
							width={31}
							height={31}
							alt="discord"
						/>
					</SnsAnchor>
				</li>

				<li>
					<SnsAnchor
						href="https://cocone.app/dHs"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_twitter.png"
							width={31}
							height={31}
							alt="twitter"
						/>
					</SnsAnchor>
				</li>
				<li>
					<SnsAnchor
						href="https://cocone.app/dHD"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_medium.png"
							width={31}
							height={31}
							alt="medium"
						/>
					</SnsAnchor>
				</li>
			</div>
		</SnsLinkWrap>
	);
};

export default SnsLink;
