import React, { useState } from 'react';

import { Wrapper, SnsWrapper, AppWrapper } from './Footer.styles';
import QrModal from './QrModal';

interface IProps {
	storeName: string;
	image: string;
}

const Footer: React.VFC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [ModalNode, setModalNode] = useState<IProps>({
		storeName: '',
		image: '',
	});

	const handleOpenModal = (storeName: string, image: string) => {
		setIsModalOpen(true);
		setModalNode({ storeName, image });
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	return (
		<Wrapper>
			<section>
				<div>
					<span>Join Meta Livly</span>
					<SnsWrapper>
						<li>
							<a href="https://cocone.app/dHQ" target="_blank" rel="noreferrer">
								<img src="/images/button/btn_discord.png" alt="discord" />
							</a>
						</li>
						<li>
							<a href="https://cocone.app/dHs" target="_blank" rel="noreferrer">
								<img src="/images/button/btn_twitter.png" alt="twitter" />
							</a>
						</li>
						<li>
							<a href="https://cocone.app/dHD" target="_blank" rel="noreferrer">
								<img src="/images/button/btn_medium.png" alt="medium" />
							</a>
						</li>
					</SnsWrapper>
				</div>
				<div>
					<AppWrapper>
						<li>
							<button
								onClick={() => handleOpenModal('Google Play', 'QR_android')}
							>
								<img
									src="/images/button/icon_google-play.png"
									alt="google icon"
									className="app-icon-image"
								/>
								<img
									src="/images/text/google-play.png"
									alt="google play"
									className="app-icon-text"
								/>
							</button>
						</li>
						<li>
							<button onClick={() => handleOpenModal('App Store', 'QR_ios')}>
								<img
									className="app-icon-image"
									src="/images/button/icon_app-store.png"
									alt="app store"
								/>
								<img
									className="app-icon-text"
									src="/images/text/app store.png"
									alt="app store"
								/>
							</button>
						</li>
					</AppWrapper>
				</div>
			</section>
			<QrModal
				isModalOpen={isModalOpen}
				handleCloseModal={handleCloseModal}
				size={548}
				modalNode={ModalNode}
			/>
		</Wrapper>
	);
};

export default Footer;
