import React from 'react';

import { Article, Inner, Title, Contents, TextBlock, Image } from './SD.styles';

const TP: React.VFC = () => {
	return (
		<Article className="section">
			<Inner>
				<TextBlock>
					<Title>Starry Dust [SD]</Title>
					<Contents>
						Starry Dust is a type of currency used in the virtual world of
						<br />
						Meta Livly that can be used to spin Gachas, charge Hom Power,
						purchase Livly Cages,
						<br />
						mint NFT items, or be cashed out by sending it to the wallet.
						<br />
						<br />
						Gather lots of SD, collect items, and earn by playing Meta Livly!
					</Contents>
				</TextBlock>
				<Image src="/images/picture/tp_flow_new.png" alt="tp" />
			</Inner>
		</Article>
	);
};

export default TP;
