import React from 'react';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
	Navigation,
	Pagination,
	EffectFade,
	Autoplay,
} from 'swiper';

import {
	Wrapper,
	Article,
	SwiperWrapper,
	SwiperNavigation,
	TextWrapper,
} from './Intro.styles';
import Footer from './Footer';

import { LeftArrow, RightArrow } from '@/assets/svg';

SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay]);

const Intro: React.VFC = () => {
	return (
		<>
			<Wrapper>
				<TextWrapper>
					<p>
						The core experience of CCP (Character Coordinating Play) services is
						collecting as many rare items as possible.
						<br />
						Meta Livly’s transformative BCCP (Blockchain Character Coordinating
						Play) service shares the same core experience
						<br />
						as traditional CCP (Character Coordinating Play services), but
						provides an additional play to earn experience.
						<br />
						The Gacha WEB3.0 system built into Meta Livly enables the item
						scarcity generated by core contents of CCP(Character Coordinating
						Play)’s business model, which is the currency($SD, CC, GP) used in
						Gacha to distribute rewards to players for two types of play
						activities.
					</p>
					<ul>
						<li>
							<Link to="/gacha-web3/overview">
								<span className="icon-image">
									<img
										src="/images/button/icon_Digital-Data-Research.png"
										alt="icon_Digital-Data-Research"
									/>
								</span>
								Digital Data Research
								<span className="material-symbols-outlined">double_arrow</span>
							</Link>
						</li>
						<li className="gacha-mining">
							<span className="icon-image">
								<img
									src="/images/button/icon_Gacha-mining.png"
									alt="icon_Gacha-mining"
								/>
							</span>
							Gacha Mining
							<span className="material-symbols-outlined">double_arrow</span>
						</li>
					</ul>
				</TextWrapper>
				<SwiperWrapper>
					<Swiper
						loop={true}
						autoplay={{ delay: 5000, disableOnInteraction: false }}
						effect={'fade'}
						fadeEffect={{ crossFade: true }}
						pagination={{ clickable: false }}
						navigation={{
							prevEl: '.swiper-button-prev',
							nextEl: '.swiper-button-next',
						}}
					>
						<SwiperSlide>
							<Article image="intro1"></Article>
						</SwiperSlide>
						<SwiperSlide>
							<Article image="intro2" />
						</SwiperSlide>
						<SwiperSlide>
							<Article image="intro3" />
						</SwiperSlide>
						<SwiperNavigation>
							<div className="swiper-button-prev">
								<LeftArrow />
							</div>
							<div className="swiper-button-next">
								<RightArrow />
							</div>
						</SwiperNavigation>
					</Swiper>
				</SwiperWrapper>
			</Wrapper>
			<Footer />
		</>
	);
};

export default Intro;
