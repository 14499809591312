import styled from '@emotion/styled';

import { InnerWrap, SwiperWrapLg } from '@/assets/styles/Common.styles';

export const Inner = styled(InnerWrap)`
	width: 82vw;
	height: auto;
`;

export const SwiperWrapper = styled(SwiperWrapLg)`
	border-radius: 28px;
	text-align: center;
	.swiper-container {
		padding: 50px 0;
	}

	.swiper-pagination {
		width: 100%;
		bottom: 18px;
		.swiper-pagination-bullet {
			width: 20px;
			height: 17px;
			background-size: contain;
			&.swiper-pagination-bullet {
				background-size: contain;
			}
		}
	}
`;

export const Title = styled.h2`
	margin-bottom: 28px;
	color: #48a8b1;
	font-size: 28px;
`;

export const Contents = styled.p`
	// padding: 0 10px;
	font-size: 12px;
	color: #6a503d;
	line-height: 18px;
	white-space: pre-line;
`;

export const SlideImg = styled.img`
	max-width: 85%;
`;

export const PrevButton = styled.button`
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	z-index: 100;
`;

export const NextButton = styled(PrevButton)`
	left: auto;
	right: 10px;
	z-index: 100;
`;
