import styled from '@emotion/styled';

import { Section, SwiperWrapLg2 } from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/bg_roadmap_re.png') 50% 50% no-repeat;
	background-size: cover;
`;

export const ContentsBox = styled.div`
	position: relative;
	margin: 117px auto 0;
	width: 1288px;
	height: 847px;
`;

export const Title = styled.span`
	margin: 0 871px 0 0;
	font-size: 55px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.6;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
`;

export const ContentsDiv = styled.div`
	margin-top: 35px;
	object-fit: contain;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #fff;
`;

export const ContentsTitle = styled.span`
	margin: 0px 1015px 38px 12px;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.1;
`;

export const ContentsList = styled.div`
	font-size: 18px;
	font-weight: lighter;
	text-align: left;

	p {
		margin-left: 15px;
	}

	.firstBox1,
	.firstBox2,
	.firstBox3,
	.firstBox4 {
		line-height: 1.56;
	}

	.firstBox1 {
		margin: 38px 120px 25px 0;
		width: 559px;
		height: 55px;
	}

	.firstBox2 {
		margin: 25px 113px 31px 0;
		width: 566px;
		height: 83px;
	}

	.firstBox3 {
		margin: 25px 113px 31px 0;
		width: 561px;
		height: 111px;
	}

	.firstBox4 {
		width: 679px;
		height: 111px;
	}

	.secondBox1,
	secondBox2 {
		line-height: 28px;
	}

	.secondBox1 {
		margin-top: 38px;
		font-size: 18px;

		.text-indent {
			text-indent: 10px;
			a {
				color: #fff;
			}
		}

		span {
			color: #00ffff;
			font-weight: 500;
		}
	}

	.secondBox2 {
		margin-top: 31px;
		line-height: 1.56;
	}

	.thirdBox {
		font-weight: normal;
		margin-top: 38px;
		line-height: 2.22;

		p {
			margin-left: 0;
		}
	}
`;

export const SubText = styled.div`
	position: absolute;
	margin-left: 290px;
	bottom: 79px;
	text-align: center;
	font-weight: 100;
	font-size: 18px;
	color: #fff;
`;

export const SwiperWrapper = styled(SwiperWrapLg2)`
	background: none;
	.swiper-container {
		height: 670px;
	}
`;
