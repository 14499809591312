import React from 'react';

import {
	ThumbnailWrap,
	Thumbnail,
	TextWrap,
	Title,
	SubTitle,
} from './PlayToEarn.styles';

import {
	LightFont,
	MobileSection,
	SectionInner,
} from '@/assets/styles/Common.styles';

const Play2Earn: React.VFC = () => {
	return (
		<MobileSection
			imageUrl="/images/picture/play_bg_m.png"
			id="scroll-play_to_earn"
			className="section"
		>
			<SectionInner>
				<Title>
					CCP to Earn<SubTitle>(Coordinate to Earn)</SubTitle>
				</Title>
				<ThumbnailWrap>
					<Thumbnail src="/images/picture/flow_new.png" alt="thumbnail" />
				</ThumbnailWrap>
				<TextWrap>
					<p>
						Collect the doodoo from your cute digital livly pets
						<br />
						to exchange them for GP and for different fashion
						<br />
						and island items through gachas.
					</p>
					<p>
						The more you decorate and customize your hom
						<br />
						and island, the better your chances to earn SD are.
					</p>
					<p>
						All of your digital assets can be minted into
						<br />
						NFTs to trade and to cash in at the marketplace!
					</p>
					<LightFont>*Details are subject to change.</LightFont>
				</TextWrap>
			</SectionInner>
		</MobileSection>
	);
};

export default Play2Earn;
