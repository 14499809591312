import React from 'react';

import { OpenBtn } from './Header.styles';

interface IProps {
	onClick: () => void;
}

const Header: React.VFC<IProps> = ({ onClick }) => {
	return (
		<OpenBtn onClick={onClick} aria-label="menu-button">
			<img src="/images/button/btn_open.png" alt="" width={23} height={21} />
		</OpenBtn>
	);
};

export default Header;
