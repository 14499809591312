import React, { useState } from 'react';

import { Route, Routes } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import GachaWeb from './components/containers/GachaWeb';
import FullpageWeb from './components/containers/FullpageWeb';
import Dashboard from './gachaWeb/containers/Dashboard/Dashboard';
import Overview from './gachaWeb/containers/Overview/Overview';
import MobileModal from './gachaWeb/common/MobildModal/MobileModal';

const App: React.VFC = () => {
	return (
		<Routes>
			<Route path="/" element={<FullpageWeb />} />
			<Route
				path="/gacha-web3"
				element={<DieviceChecker browser={<GachaWeb />} />}
			/>
			<Route
				path="/gacha-web3/overview"
				element={<DieviceChecker browser={<Overview />} />}
			/>
			<Route
				path="/gacha-web3/dashboard"
				element={<DieviceChecker browser={<Dashboard />} />}
			/>
		</Routes>
	);
};

const DieviceChecker = ({ browser }: { browser: JSX.Element }) => {
	const [isModalOpen, setIsModalOpen] = useState(true);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<BrowserView>{browser}</BrowserView>
			<MobileView>
				<MobileModal
					isModalOpen={isModalOpen}
					handleCloseModal={handleCloseModal}
					width={90}
					height={90}
					browser={browser}
				/>
			</MobileView>
			{/* <TabletView>
				<MobileModal
					isModalOpen={isModalOpen}
					handleCloseModal={handleCloseModal}
					width={90}
					height={90}
					browser={browser}
				/>
			</TabletView> */}
		</>
	);
};

export default App;
