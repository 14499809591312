import styled from '@emotion/styled';

export const SnsLinkWrap = styled.ul`
	display: flex;
	list-style: none;
	position: fixed;
	right: 13px;
	bottom: 8px;
	z-index: 1;

	li {
		margin-bottom: 10px;
`;

export const SnsAnchor = styled.a``;

export const SnsImg = styled.img``;

export const BubbleBlock = styled.div`
	position: relative;
	width: 98px;
	margin-right: 2px;

	.bubble {
		position: absolute;
		top: 6px;
		display: block;
	}

	.text {
		position: absolute;
		left: 8%;
		top: 10px;
		font-weight: bold;
		font-size: 10px;
	}
`;
