import React from 'react';

import { ThumbnailWrap, Thumbnail, TextWrap, Title } from './SD.styles';

import { MobileSection, SectionInner } from '@/assets/styles/Common.styles';

const SD: React.VFC = () => {
	return (
		<MobileSection
			imageUrl="/images/picture/tp_bg_m.png"
			id="scroll-sd"
			className="section"
		>
			<SectionInner>
				<Title>SD</Title>
				<ThumbnailWrap>
					<Thumbnail src="/images/picture/sd_flow_m_new.png" alt="tp" />
				</ThumbnailWrap>
				<TextWrap>
					<p>
						Starry Dust is a type of currency
						<br /> used in the virtual world of Meta Livly
						<br /> that can be used to spin Gachas, charge Hom Power,
						<br /> purchase Livly Cages, mint NFT items,
						<br /> or be cashed out by sending it to the wallet.
					</p>
					<p>
						Gather lots of SD, collect items, <br />
						and earn by playing Meta Livly!
					</p>
				</TextWrap>
			</SectionInner>
		</MobileSection>
	);
};

export default SD;
