import styled from '@emotion/styled';

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	text-align: center;

	div {
		span {
			display: inline-block;
			width: 32px;
			margin-bottom: 17px;
		}

		h2 {
			font-size: 4vmin;
			font-weight: normal;
			line-height: 1.57;
			margin-bottom: 17px;
		}

		p {
			font-size: 4vmin;
			font-weight: 300;
			line-height: 1.57;
		}
	}
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 15px;
	right: 15px;
`;
