import styled from '@emotion/styled';

export const Title = styled.h2`
	color: #feca15;
	font-size: 28px;
`;

export const ThumbnailWrap = styled.div`
	margin-top: 29px;
	margin-bottom: 36px;
`;

export const Thumbnail = styled.img`
	max-width: 283px;
`;

export const TextWrap = styled.div`
	max-width: 274px;
	margin: 0 auto;
	p {
		color: #fff;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-weight: lighter;
		}
	}
`;

export const TransparentLink = styled.a`
	display: inline-block;
	width: 171px;
	margin-bottom: 36px;
	padding: 7px;
	border-radius: 31px;
	border: solid 2px #fff;
	color: #fff;
	font-size: 11px;
	font-weight: 100;
	letter-spacing: normal;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.1);

	strong {
		font-weight: normal;
	}
`;
