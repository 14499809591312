import React from 'react';

import {
	Footer,
	Home,
	NFTsItem,
	PlayToEarn,
	Roadmap,
	Service,
	SD,
} from '@/components/pc';

const FullPagePc: React.VFC = () => {
	return (
		<section>
			<Home />
			<Service />
			<PlayToEarn />
			<NFTsItem />
			<SD />
			<Roadmap />
			<Footer />
		</section>
	);
};

export default FullPagePc;
