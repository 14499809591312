import React from 'react';

import { Link } from 'react-router-dom';

import { Wrapper, Nav, DropDownWrapper, DropDown } from './Header.styles';

const Header: React.VFC = () => {
	return (
		<Wrapper>
			<section>
				<h1>
					<Link to="/gacha-web3">Gacha WEB 3.0</Link>
				</h1>
				<Nav>
					<ul>
						<DropDownWrapper>
							Digital Data Research
							<span className="material-symbols-outlined">change_history</span>
							<DropDown className="header-dropDown">
								<li>
									<Link to="/gacha-web3/overview">Overview</Link>
								</li>
								<li>
									<Link to="/gacha-web3/dashboard">Dashboard</Link>
								</li>
							</DropDown>
						</DropDownWrapper>
						<li className="disable">Gacha Mining</li>
					</ul>
				</Nav>
			</section>
		</Wrapper>
	);
};

export default Header;
