import styled from '@emotion/styled';

import { InnerWrap } from '@/assets/styles/Common.styles';

export const HeaderWrap = styled.div`
	margin-left: 120px;
	width: 100%;
	height: 106px;
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
`;

export const Inner = styled(InnerWrap)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Gnb = styled.nav`
	a {
		display: inline-block;
		color: #fff;
		font-weight: bold;
		line-height: 30px;
		padding: 0 19px;

		& + a {
			margin-left: 21px;
		}

		&.mint-color {
			color: #00fff2;
		}
		&.active {
			color: #000;
			background: #fff;
			border-radius: 15px;
		}
	}
`;

export const GachaLink = styled.span`
	padding-left: 20px;
`;

export const MarketPlaceButton = styled.a`
	position: relative;
	height: 25px;
	margin-left: 70px !important;

	&:before {
		content: '';
		position: absolute;
		left: -32px;
		top: 3px;
		width: 1px;
		height: 25px;
		background: #fff;
	}
`;

export const GachaButton = styled.a`
	position: relative;
	height: 25px;
	margin-left: 18px !important;

	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	left: -32px;
	// 	top: 3px;
	// 	width: 1px;
	// 	height: 25px;
	// 	background: #fff;
	// }
`;
