import React from 'react';

import { useRecoilValue } from 'recoil';

import {
	HeaderWrap,
	Inner,
	Gnb,
	MarketPlaceButton,
	GachaButton,
} from './Header.styles';

import { anchorState } from '@/state/anchor';

interface IProps {
	anchors: string[];
}

const Header: React.VFC<IProps> = ({ anchors }) => {
	const anchor = useRecoilValue(anchorState);

	return (
		<HeaderWrap>
			<Inner>
				<Gnb>
					{anchors.map((item) => (
						<a
							href={`#${item}`}
							key={item}
							className={anchor === item ? 'active' : ''}
						>
							{item.replaceAll('_', ' ')}
						</a>
					))}
					<a
						className="mint-color"
						href="https://cocone.app/dxn"
						target="_blank"
						rel="noreferrer"
					>
						Whitepaper
					</a>
					<MarketPlaceButton
						href="https://cocone.app/dxC"
						target="_blank"
						rel="noreferrer"
					>
						Marketplace
					</MarketPlaceButton>
					<GachaButton href="/gacha-web3" target="_blank" rel="noreferrer">
						Gacha Web3.0
					</GachaButton>
				</Gnb>
			</Inner>
		</HeaderWrap>
	);
};

export default Header;
