import styled from '@emotion/styled';

import { InnerWrap, Section } from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/nfts_bg.png') 50% 50% no-repeat;
	background-size: cover;
`;

export const Inner = styled(InnerWrap)`
	width: 1526px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Title = styled.h3`
	font-size: 55px;
	color: #fff;
	font-weight: bold;
	margin-bottom: 33px;
`;

export const Contents = styled.p`
	max-width: 773px;
	font-size: 18px;
	color: #fff;
	line-height: 1.83;
	display: block;
`;

export const ListBlock = styled.ul`
	max-width: 773px;
	margin-top: 20px;
`;

export const ListItem = styled.li`
	font-size: 18px;
	color: #fff;
	line-height: 1.83;
	position: relative;
	padding-left: 15px;
	font-weight: lighter;
	strong {
		display: block;
		font-weight: bold;
	}
	& + li {
		margin-top: 20px;
	}
	&:before {
		content: '';
		display: block;
		background: #fff;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		top: 9px;
		left: 0;
	}
`;

export const Image = styled.img`
	width: 725px;
	height: 725px;
`;

export const TransparentLink = styled.a`
	display: inline-block;
	width: 275px;
	margin: 0 526px 49px 0;
	padding: 11px 0px;
	border-radius: 31px;
	border: solid 2px #fff;
	color: #fff;
	font-size: 19px;
	font-weight: 100;
	letter-spacing: normal;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.1);

	strong {
		font-weight: normal;
	}
`;
