import React from 'react';

import { CloseButton, Wrapper } from './MobileModal.styles';

import EventModal from '@/components/common/Modal/EventModal';
import { IcoX } from '@/assets/svg';

interface IProps {
	isModalOpen: boolean;
	handleCloseModal: () => void;
	width: number;
	height: number;
	browser: JSX.Element;
}

const MobileModal: React.FC<IProps> = ({
	isModalOpen,
	handleCloseModal,
	width,
	height,
	browser,
}) => {
	return (
		<>
			<EventModal
				isModalOpen={isModalOpen}
				width={width}
				height={height}
				handleCloseModal={handleCloseModal}
				overlay={'rgba(0, 0, 0, 0.7)'}
			>
				<Wrapper>
					<CloseButton onClick={handleCloseModal}>
						<IcoX />
					</CloseButton>
					<div>
						<span>
							<img
								src="/images/gachaWeb/danger_icon@3x.png"
								alt="danger icon"
							/>
						</span>
						<h2>
							Meta Livly Gacha WEB 3.0 webpage
							<br /> is optimized for the web environment.
						</h2>
						<p>
							There may be compatibility
							<br /> issues on mobile and tablet devices,
							<br /> so please use a desktop browser.
						</p>
					</div>
				</Wrapper>
			</EventModal>
			{browser}
		</>
	);
};

export default MobileModal;
