import React from 'react';

import {
	PhaseWrap,
	Title,
	Notice,
	Article,
	PhaseThreeWrapper,
} from './Roadmap.styles';

import { MobileSection } from '@/assets/styles/Common.styles';

const Roadmap: React.VFC = () => {
	return (
		<MobileSection
			imageUrl="/images/picture/bg_roadmap_re@3x.png"
			className="section"
			id="scroll-roadmap"
		>
			<div>
				<Title>Roadmap</Title>
				<PhaseWrap>
					<Article>
						<h3>Phase 1</h3>
						<ul>
							<li>
								<h4>July 2021</h4>
								<p>
									Livly Island, the original service of Meta Livly,
									<br /> official launch in Japan
								</p>
							</li>
							<li>
								<h4>December 2021 : Team Building</h4>
								<p>
									The P2E development decision and new team building
									<br /> for Meta Livly
								</p>
								<p>Ideation and development start</p>
							</li>
							<li>
								<h4>
									Q1 2022 : Concept and Ideation Finish, Launch of Social Media
								</h4>
								<p>
									In-house test of the alpha build and reviewing tokenomics
									structure
								</p>
								<p>Official homepage reveal</p>
								<p> Social media channel open</p>
							</li>
							<li>
								<h4>Q2 2022 : Service Development</h4>

								<p>Digital Data Research&#40;Item Staking&#41; development</p>
								<p>
									$SD tokenomics system development &#40;interlink with MOOI
									Wallet&#41;
								</p>
								<p>
									In-app item NFT minting/burning system development
									<br />
									&#40;interlink with MOOI Wallet&#41;
								</p>
							</li>
						</ul>
					</Article>
					<Article>
						<h3>Phase 2</h3>
						<ul>
							<li>
								<h4>
									Q3 2022 : Meta Livly Offical Launch, Onboarding on MOOI
									Network
								</h4>

								<p>Pre-minting 9,999 First Edition NFTs</p>
								<p> NFT airdrop, USDT &#38; In-game items giveaway event</p>
								<p className="text-indent">
									Please refer to the link for more detail :&nbsp;
									<a
										href="https://metalivly.medium.com"
										target="_blank"
										rel="noreferrer"
									>
										Medium
									</a>
								</p>
								<p>App Store Pre-registration &#40;iOS / Android&#41; </p>
								<p>
									NFT Pre-sale on Meta Livly&#39;s NFT Marketplace &#40;Private
									&#38;Public&#41;
								</p>
								<p>
									<span>Meta Livly Official Launch</span> on August 24, 2022
									&#40;iOS / Android&#41;
								</p>
								<p>
									Digital Data Research&#40;Item Staking&#41; First Round Start
									&#40;In-game&#41;
								</p>
								<p>
									Gacha WEB 3.0 Dashboard website open <br />
									&#40;Archive for previous round result of item staking&#41;
								</p>
								<p>
									Implement In-App Purchases and add a new currency called
									<br />
									Computer Coin
								</p>
							</li>
							<li>
								<h4>Within 2022</h4>
								<p>Operation stabilization and community growth</p>
								<p>More variety of events </p>
							</li>
						</ul>
					</Article>
					<Article>
						<h3>Phase 3</h3>
						<PhaseThreeWrapper>
							<li>
								<h4>In-game package shop open</h4>
							</li>
							<li>
								<h4>Gacha WEB 3.0 - Gacha Mining update</h4>
							</li>
							<li>
								<h4>Additional languages support update</h4>
							</li>
							<li>
								<h4>
									In-game social network enhancement : real-time chat update
									<br />
									&#40;Livly Park&#41;
								</h4>
							</li>
							<li>
								<h4>Cross platform channels launch &#40;PC / Mac&#41;</h4>
							</li>
							<li>
								<h4>UGC&#40;User Generated Content&#41;Item Studio update</h4>
							</li>
							<li>
								<h4>Livly Park&#39;s land sale and trade update</h4>
							</li>
							<li>
								<h4>More playable contents for Livly Park&#39;s Land update</h4>
							</li>
						</PhaseThreeWrapper>
					</Article>
				</PhaseWrap>
				<Notice>
					The Roadmap of Meta Livly can be constantly
					<br /> adjusted depending on priority and user&#39;s need. <br />
					Join our community so that you don&#39;t miss the latest update!
				</Notice>
			</div>
		</MobileSection>
	);
};

export default Roadmap;
