import styled from '@emotion/styled';

export const Title = styled.h2`
	color: #3de1ff;
	font-size: 28px;
`;

export const SubTitle = styled.p`
	color: #3de1ff;
	font-size: 15px;
	font-weight: normal;
	line-height: 18px;
`;

export const ThumbnailWrap = styled.div`
	margin: 23px 112px 20px 82px;
`;

export const Thumbnail = styled.img`
	max-width: 181px;
`;

export const TextWrap = styled.div`
	p {
		color: #fff;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
`;
