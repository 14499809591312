import React, { useMemo, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';

import 'swiper/swiper.min.css';

import {
	Article,
	Title,
	SubText,
	ContentsDiv,
	ContentsBox,
	ContentsTitle,
	ContentsList,
	SwiperWrapper,
} from './Roadmap.styles';

SwiperCore.use([Pagination]);

const Roadmap: React.VFC = () => {
	const [, setIsSwiper] = useState<SwiperCore>();

	const settings = useMemo<Swiper>(
		() => ({
			pagination: { clickable: false },
			loop: true,
			onSwiper: (swiper) => {
				setIsSwiper(swiper);
			},
		}),
		[],
	);
	return (
		<Article className="section">
			<ContentsBox>
				<Title>Roadmap</Title>
				<SwiperWrapper>
					<Swiper {...settings}>
						<SwiperSlide>
							<ContentsDiv>
								<ContentsTitle>Phase 1</ContentsTitle>
								<ContentsList>
									<div className="firstBox1">
										<strong>˙July 2021</strong>
										<p>
											- Livly Island, the original service of Meta Livly,
											official launch in Japan
										</p>
									</div>

									<div className="firstBox2">
										<strong>˙December 2021 : Team Building</strong>
										<p>
											- The P2E development decision and new team building for
											Meta Livly
										</p>
										<p>- Ideation and development start</p>
									</div>

									<div className="firstBox3">
										<strong>
											˙Q1 2022 : Concept and Ideation Finish, Launch of Social
											Media
										</strong>
										<p>
											- In-house test of the alpha build and reviewing
											tokenomics structure
										</p>
										<p>- Official homepage reveal</p>
										<p>- Social media channel open</p>
									</div>

									<div className="firstBox4">
										<strong>˙Q2 2022 : Service Development</strong>
										<p>- Digital Data Research(Item Staking) development</p>
										<p>
											- $SD tokenomics system development (interlink with MOOI
											Wallet)
										</p>
										<p>
											- In-app item NFT minting/burning system development
											(interlink with MOOI Wallet)
										</p>
									</div>
								</ContentsList>
							</ContentsDiv>
						</SwiperSlide>
						<SwiperSlide>
							<ContentsDiv>
								<ContentsTitle>Phase 2</ContentsTitle>
								<ContentsList>
									<div className="secondBox1">
										<strong>
											˙Q3 2022 : Meta Livly Offical Launch, Onboarding on MOOI
											Network
										</strong>
										<p>- Pre-minting 9,999 First Edition NFTs</p>
										<p>
											- NFT airdrop, USDT &amp; In-game items giveaway event
										</p>
										<p className="text-indent">
											- Please refer to the link for more detail :&nbsp;
											<a
												href="https://metalivly.medium.com"
												target="_blank"
												rel="noreferrer"
											>
												Medium
											</a>
										</p>
										<p>- App Store Pre-registration (iOS / Android)</p>
										<p>
											- NFT Pre-sale on Meta Livly&#39;s NFT Marketplace
											(Private &amp; Public)
										</p>
										<p>
											- <span>Meta Livly Official Launch</span> on August 24,
											2022 (iOS / Android)
										</p>
										<p>
											- Digital Data Research(Item Staking) First Round Start
											(In-game)
										</p>
										<p>- Gacha WEB 3.0 Dashboard website open</p>
										<p>(Archive for previous round result of item staking)</p>
										<p>
											- Implement In-App Purchases and add a new currency called
											Computer Coin
										</p>
									</div>

									<div className="secondBox2">
										<strong>˙Within 2022</strong>
										<p>- Operation stabilization and community growth</p>
										<p>- More variety of events</p>
									</div>
								</ContentsList>
							</ContentsDiv>
						</SwiperSlide>
						<SwiperSlide>
							<ContentsDiv>
								<ContentsTitle>Phase 3</ContentsTitle>
								<ContentsList>
									<div className="thirdBox">
										<p>˙In-game package shop open</p>
										<p>˙Gacha WEB 3.0 - Gacha Mining update</p>
										<p>˙Additional languages support update</p>
										<p>
											˙In-game social network enhancement: real-time chat update
											(Livly Park)
										</p>
										<p>˙Cross platform channels launch (PC / Mac)</p>
										<p>˙UGC(User Generated Content) Item Studio update</p>
										<p>˙Livly Park&#39;s land sale and trade update</p>
										<p>
											˙More playable contents for Livly Park&#39;s Land update
										</p>
									</div>
								</ContentsList>
							</ContentsDiv>
						</SwiperSlide>
					</Swiper>
				</SwiperWrapper>
				<SubText>
					The Roadmap of Meta Livly can be constantly adjusted depending on
					priority and user&#39;s need.
					<br />
					Join our community so that you don&#39;t miss the latest update!
				</SubText>
			</ContentsBox>
		</Article>
	);
};

export default Roadmap;
