import styled from '@emotion/styled';

export const Wrapper = styled.div`
	padding-top: 30px;
	background-image: url('/images/picture/bg_roadmap_re@3x.png');
	background-size: cover;
	text-align: center;
`;

export const Title = styled.h2`
	color: #fe7e00;
	font-size: 28px;
	padding-top: 35px;
`;

export const PhaseWrap = styled.div`
	margin: 44px 12px;
`;

export const PhaseInner = styled.div`
	margin-bottom: 38px;
	ul {
		li {
			font-size: 12px;
			line-height: 22px;
			color: #fff;
		}
	}
`;

const PhaseFrame = styled.div`
	position: relative;
	width: 255px;
	height: 34px;
	margin-bottom: 17px;
	background-size: cover;

	span {
		position: absolute;
		top: 7px;
		right: 33px;
		font-size: 18px;
		line-height: 18px;
		color: #fff;
	}
`;

export const PhaseLine1 = styled(PhaseFrame)`
	background-image: url('/images/picture/roadmap_line01_m.png');
`;

export const PhaseLine2 = styled(PhaseFrame)`
	background-image: url('/images/picture/roadmap_line02_m.png');
`;

export const PhaseLine3 = styled(PhaseFrame)`
	background-image: url('/images/picture/roadmap_line03_m.png');
`;

export const PhaseLine4 = styled(PhaseFrame)`
	background-image: url('/images/picture/roadmap_line04_m.png');
`;

export const Article = styled.article`
	margin-bottom: 37px;
	text-align: left;
	color: #fff;

	h3 {
		border-bottom: 1px solid #afafaf;
		padding-bottom: 10px;
		margin: 0 10px 30px;
		font-size: 18px;
		font-weight: bold;
	}

	ul {
		li {
			margin: 15px 10px;

			h4 {
				position: relative;
				margin-bottom: 7px;
				font-size: 11px;
				font-weight: normal;

				&:before {
					content: '˙ ';
					position: absolute;
					left: -10px;
				}
			}
			p {
				position: relative;
				margin: 0px 5px;
				font-weight: 100;
				font-size: 11px;
				line-height: 2;

				span {
					font-weight: 500;
					color: #0ff;
				}

				&:before {
					content: '-';
					position: absolute;
					left: -6px;
				}

				&.text-indent {
					text-indent: 10px;
					a {
						color: #fff;
					}
				}
			}
		}
	}
`;

export const PhaseThreeWrapper = styled.ul`
	li {
		margin: 0px 10px;
		h4 {
			line-height: 2;
		}
	}
`;

export const Notice = styled.p`
	font-size: 9px;
	font-weight: 100;
	line-height: 1.56;
	color: #fff;
	margin-top: 54px;
`;
