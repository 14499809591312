import styled from '@emotion/styled';

export const Wrapper = styled.section`
	position: relative;
	width: 100vw;
	height: 82vh;
`;

export const TextWrapper = styled.section`
	position: absolute;
	left: 10%;
	top: 50px;
	width: 1300px;
	color: #fff;
	z-index: 2;

	> p {
		margin-bottom: 58px;
		font-size: 20px;
		line-height: 2;
		letter-spacing: normal;
	}

	ul {
		li {
			width: 400px;
			border: 1px solid #fff;
			margin-bottom: 36px;
			font-size: 25px;
			cursor: pointer;
			background: rgba(255, 255, 255, 0.2);

			a {
				display: inline-block;
			}

			span {
				vertical-align: middle;

				img {
					vertical-align: middle;
				}
			}

			.icon-image {
				display: inline-block;
				width: 100px;
				margin-right: 18px;
				padding: 10px;
			}

			&.gacha-mining {
				position: relative;
				border: 1px solid #9ea4b2;
				background: none;
				color: #9ea4b2;
				&:hover {
					background: #fff;
					transition: all 0.5s;
					color: transparent;
					&:after {
						content: 'Coming Soon';
						display: block;
						line-height: 100px;
						text-align: center;
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						color: #000;
						transition: all 0.5s;
					}
				}
			}
		}
	}
`;

export const Article = styled.article<{ image: string }>`
	height: 77vh;
	overflow-y: hidden;
	color: #fff;
	background: #000;
	overflow: hidden;
	background-image: url(/images/gachaWeb/${(props) => props.image}.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;

	> div {
	}
`;

export const SwiperWrapper = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: #fff;

	.swiper-pagination {
		z-index: 5;
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);

		.swiper-pagination-bullet {
			display: inline-block;
			width: 96px;
			height: 6px;
			background: #fff;
			opacity: 0.2;

			& + .swiper-pagination-bullet {
				margin-left: 16px;
			}

			&.swiper-pagination-bullet-active {
				background: #fff;
				opacity: 1;
			}
		}
	}
`;

export const SwiperNavigation = styled.div`
	> div {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		cursor: pointer;

		&.swiper-button-prev {
			left: 50px;
			height: 300px;
			z-index: 2;
		}

		&.swiper-button-next {
			right: 50px;
			height: 300px;
			z-index: 2;
		}
	}
`;
