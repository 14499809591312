import React from 'react';

import { Link } from 'react-router-dom';

import { Wrapper, DashboardButton, Explanation } from './Footer.styles';

const Footer: React.VFC = () => {
	return (
		<Wrapper>
			<section>
				<DashboardButton>
					<div>
						<Link to="/gacha-web3/dashboard">
							Dashboard
							<span className="material-symbols-outlined">north_east</span>
						</Link>
					</div>
				</DashboardButton>
				<Explanation>
					<p>
						The ranking and reward pool differs every round operated every 24
						hours, and the distribution amount is determined
						<br /> and compensated according to the ranking at the time of
						aggregation as of 00:00. &#40; Standard time zone: UTC+0&#41;
						<br />
						Check the Dashboard for the reward pool and ranking information.
					</p>
				</Explanation>
			</section>
		</Wrapper>
	);
};

export default Footer;
