import axios from 'axios';

import { RankResponse } from './rankModel';
import { ResearchInfoResponse } from './rewardModel';

export const fetchRoundTime = async (
	startDate: string,
): Promise<ResearchInfoResponse> => {
	const data = await axios.get(
		`${process.env.REACT_APP_REWARD_API_URL}/${startDate}`,
	);

	return data.data.StakingRoundInfoResponse;
};

interface SearchValue {
	paging_number: number;
	round_time: string;
	search_type: number;
	search_value: string;
}

export const fetchUserInfo = async (
	userSearchValue: SearchValue,
): Promise<RankResponse> => {
	const data = await axios.post(`${process.env.REACT_APP_RANK_API_URL}`, {
		paging_number: userSearchValue.paging_number,
		round_time: userSearchValue.round_time,
		search_type: userSearchValue.search_type,
		search_value: userSearchValue.search_value,
	});

	return data.data.RankResponse;
};
