import React from 'react';

import {
	Article,
	Inner,
	Title,
	Contents,
	ListBlock,
	ListItem,
	Image,
	TransparentLink,
} from './NFTsItem.styles';

const NFTsItem: React.VFC = () => {
	return (
		<Article className="section">
			<Inner>
				<div>
					<Title>NFT Items</Title>
					<TransparentLink
						href="https://cocone.app/dxC"
						target="_blank"
						rel="noreferrer"
					>
						Visit the <strong>marketplace &gt;</strong>
					</TransparentLink>
					<Contents>
						Livlies, clothes, accessories, and island decoration items that you
						collect throughout
						<br />
						your Meta Livly playthrough are recognized as your own assets.
						<br />
						These assets can be held as NFTs or traded with other players
						through the NFT marketplace.
						<br />
						Use Meta Livly to gain access to valuable NFTs and earn some extra
						cash!
					</Contents>
					<ListBlock>
						<ListItem>
							<strong>Livly</strong>
							There are a variety of cute and loving livly species to choose
							from!
							<br />
							The same livly species are customizable with different colors so
							try
							<br />
							experimenting with different colors when sending them out as NFTs.
						</ListItem>
						<ListItem>
							<strong>Hom</strong>
							Secure hom NFTs that are equipped with a combination of stylist
							and diverse items!
						</ListItem>
						<ListItem>
							<strong>Island</strong>
							Secure island NFTs with trees that bear fruit that can be used to
							transform livlies
							<br />
							in the future!
						</ListItem>
					</ListBlock>
				</div>
				<Image src="/images/picture/nfts_item.png" alt="nfts" />
			</Inner>
		</Article>
	);
};

export default NFTsItem;
