import styled from '@emotion/styled';

export const Title = styled.h2`
	color: #feca15;
	font-size: 28px;
`;

export const ThumbnailWrap = styled.div`
	margin-top: 35px;
	margin-bottom: 40px;
`;

export const Thumbnail = styled.img`
	max-width: 284px;
	height: 417px;
`;

export const TextWrap = styled.div`
	max-width: 300px;
	margin: 0 auto;
	p {
		color: #fff;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
