import React, { useState } from 'react';

import ReactModal from 'react-modal';

import { VideoWrapper, MobileVideoWrapper } from './Modal.styles';

import useWindowDimensions from '@/Hooks/useWindowDemensions';

interface IProps {
	open: boolean;
	mute: boolean;
}

export const ModalApp: React.FC<IProps> = ({ open, mute }) => {
	const [isOpen, setOpen] = useState(open);
	const [isMute, setMute] = useState(mute);
	const { width, height } = useWindowDimensions();

	let modalWidth = width >= 1920 ? 1920 : width;
	const modalHeight =
		modalWidth * (9 / 16) >= height ? height : modalWidth * (9 / 16);

	if (modalHeight < 1080) {
		modalWidth = modalHeight * (16 / 9);
	}

	const handleModalClick = () => {
		setOpen(true);
		setMute(false);
	};

	return (
		<>
			<div onClick={handleModalClick}>
				{/* <span>Launch Movie</span> */}
				<img
					src="/images/common/play_movie.png"
					alt="Play Movie"
					width={261}
					height={51}
				/>
			</div>
			{/* <ModalWrapper> */}
			<ReactModal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						zIndex: '10000',
					},
					content: {
						inset: '0px',
						width: modalWidth,
						height: modalHeight,
						margin: 'auto auto',
						border: '0px solid #ccc',
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						overflow: 'auto',
						WebkitOverflowScrolling: 'touch',
						borderRadius: '4px',
						outline: 'none',
						padding: '0px',
					},
				}}
				ariaHideApp={false}
			>
				<VideoWrapper>
					<video
						autoPlay
						muted={isMute}
						width={modalWidth}
						height={modalHeight}
						onEnded={() => setOpen(false)}
					>
						<source src="/videos/MetaLivly_new.mp4" type="video/mp4" />
					</video>
					<button onClick={() => setOpen(false)} className="close-btn">
						x
					</button>
				</VideoWrapper>
			</ReactModal>
			{/* </ModalWrapper> */}
		</>
	);
};

export const MobileModalApp: React.FC = () => {
	const [isOpen, setOpen] = useState(false);

	const handleModalClick = () => {
		setOpen(true);
	};

	return (
		<>
			<div onClick={handleModalClick}>
				<span>Launch Movie</span>
			</div>
			<ReactModal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						zIndex: '10000',
					},
					content: {
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						objectPosition: 'center center',
						inset: '0px',
						width: '100vw',
						height: '100vh',
						objectFit: 'cover',
						margin: 'auto auto',
						border: '0px solid #ccc',
						overflow: 'auto',
						WebkitOverflowScrolling: 'touch',
						borderRadius: '4px',
						outline: 'none',
						padding: '0px',
					},
				}}
			>
				<MobileVideoWrapper>
					<video controls autoPlay onEnded={() => setOpen(false)}>
						<source src="/videos/MetaLivly_new.mp4" type="video/mp4" />
					</video>
					<button onClick={() => setOpen(false)} className="close-btn">
						x
					</button>
				</MobileVideoWrapper>
			</ReactModal>
		</>
	);
};
