import React, { useCallback, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Oval } from 'react-loader-spinner';

import * as DashboardStyles from './Dashboard.styles';
import { fetchRoundTime, fetchUserInfo } from './useSearchFilter';

import Header from '@/gachaWeb/common/Header/Header';
import { FirstArrow, LastArrow, NextArrow, PrevArrow } from '@/assets/svg';
import takeComma from '@/gachaWeb/utils/takeComma';

import 'react-datepicker/dist/react-datepicker.css';

interface SearchValue {
	paging_number: number;
	round_time: string;
	search_type: number;
	search_value: string;
}

const Dashboard: React.VFC = () => {
	const UTC_NOW_DATE = new Date(
		new Date().getTime() + new Date().getTimezoneOffset() * 60000,
	);
	const [startDate, setStartDate] = useState(
		new Date(UTC_NOW_DATE.setDate(UTC_NOW_DATE.getDate() - 1)),
	);
	const [selectedDate, setSelectedDate] = useState<string>(
		dayjs(startDate).format('YYYY-MM-DD'),
	);
	const [userInputValue, setUserInputValue] = useState<string>('');
	const [userSearchType, setUserSearchType] = useState<number>(1);
	const [userSearchValue, setUserSearchValue] = useState<SearchValue>({
		paging_number: 1,
		round_time: selectedDate,
		search_type: 0,
		search_value: userInputValue,
	});

	const userParentsRef = useRef<HTMLTableElement | null>(null);
	const userChildRef = useRef<HTMLTableSectionElement | null>(null);

	const roundTimeQuery = useQuery(['roundTime', selectedDate], () =>
		fetchRoundTime(selectedDate),
	);
	const userInfoQuery = useQuery(['userInfo', userSearchValue], () =>
		fetchUserInfo(userSearchValue),
	);

	const scrollToTop = () => {
		if (userParentsRef.current) {
			userParentsRef.current.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	const handleUserScrollTop = useCallback(() => {
		if (userParentsRef.current && userChildRef.current) {
			userParentsRef.current.focus();

			userParentsRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [userParentsRef, userChildRef]);

	const handleUserScrollBottom = useCallback(() => {
		if (userParentsRef.current && userChildRef.current) {
			userParentsRef.current.focus();

			userParentsRef.current.scrollTo({
				top: userChildRef.current.clientHeight,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [userParentsRef, userChildRef]);

	const handleSearchNameButton = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserSearchType(parseInt(e.target.value));
	};

	const handleChangeDate = useCallback(() => {
		setSelectedDate(dayjs(startDate.toUTCString()).format('YYYY-MM-DD'));
		setUserSearchValue({
			...userSearchValue,
			paging_number: 1,
			round_time: dayjs(startDate.toUTCString()).format('YYYY-MM-DD'),
			search_type: 0,
			search_value: '',
		});
		setUserSearchType(1);
		setUserInputValue('');
	}, [startDate, userSearchValue]);

	const handleSearchValue = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.preventDefault();

		setUserSearchValue({
			...userSearchValue,
			search_type: userInputValue === '' ? 0 : userSearchType,
			search_value: userInputValue,
		});
	};

	// console.log('roundTimeQuery', roundTimeQuery.data);
	// console.log('userInfoQuery', userInfoQuery.data);
	return (
		<DashboardStyles.Main>
			<Header />
			<section>
				<DashboardStyles.DateWrapper>
					<div>
						<h2>Date</h2>
						<DashboardStyles.DatePickerWrapper>
							<DatePicker
								selected={startDate}
								onChange={(
									date: Date,
									event: React.ChangeEvent<HTMLInputElement>,
								) => {
									event.stopPropagation();
									setStartDate(date);
								}}
								disabledKeyboardNavigation //활성화 되있던 Day가 다른 월의 동일 Day에도 활성화 되어있는 문제를 해결하려고 사용
								maxDate={
									new Date(UTC_NOW_DATE.setDate(UTC_NOW_DATE.getDate() + 1))
								} //utc로 현재 선택된 날짜보다 뒷날짜로 max값 잡음(그래야 현재 시간을 기준으로 max값을 잡을 수 있음)
							/>
							<span>
								<img src="/images/gachaWeb/calendar@3x.png" alt="calender" />
							</span>
						</DashboardStyles.DatePickerWrapper>
						<button onClick={handleChangeDate}>Select Date</button>
					</div>
				</DashboardStyles.DateWrapper>

				<DashboardStyles.RoundDisplayWrapper>
					<ul>
						<li>
							<p>
								Round:&nbsp;
								{roundTimeQuery.isLoading ? (
									'Loading data...'
								) : (
									<>
										{roundTimeQuery.isError || !roundTimeQuery.data.round_id
											? 'N/A'
											: roundTimeQuery.data.round_time}
									</>
								)}
							</p>
							<p>
								Research Topic:&nbsp;
								{roundTimeQuery.isLoading ? (
									'Loading data...'
								) : (
									<DashboardStyles.AbbreviationWord>
										{roundTimeQuery.isError || !roundTimeQuery.data.round_id
											? 'N/A'
											: roundTimeQuery.data.research_theme_name_list.toString()}
									</DashboardStyles.AbbreviationWord>
								)}
							</p>
						</li>
						<li>
							<p>
								Participants:&nbsp;
								{roundTimeQuery.isLoading ? (
									'Loading data...'
								) : (
									<>
										{roundTimeQuery.isError || !roundTimeQuery.data.round_id
											? 'N/A'
											: roundTimeQuery.data.participants}
									</>
								)}
							</p>
							<p>
								Submitted Data:&nbsp;
								{roundTimeQuery.isLoading ? (
									'Loading data...'
								) : (
									<>
										{roundTimeQuery.isError || !roundTimeQuery.data.round_id
											? 'N/A'
											: roundTimeQuery.data.coordi_submit_count}
									</>
								)}
							</p>
						</li>
					</ul>
				</DashboardStyles.RoundDisplayWrapper>
			</section>
			<DashboardStyles.StatusContainer>
				<DashboardStyles.WidthFixSize>
					{roundTimeQuery.isLoading ? (
						<DashboardStyles.LodingWrapper>
							<Oval
								height={82}
								width={82}
								color="#000"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
								ariaLabel="oval-loading"
								secondaryColor="#888"
								strokeWidth={5}
								strokeWidthSecondary={5}
							/>
							<h2>Loading data...</h2>
						</DashboardStyles.LodingWrapper>
					) : (
						<>
							{roundTimeQuery.isError ||
							!roundTimeQuery.data.round_id ||
							(roundTimeQuery.data.research_theme_name_list.length > 0 &&
								!roundTimeQuery.data.research_prize_response
									?.reward_pool_list &&
								!userInfoQuery.data?.rank_user_info_list) ? (
								<DashboardStyles.ErrorWrapper>
									<div>
										<h2>
											<img
												src="/images/gachaWeb/icon_nodata@3x.png"
												alt="nodata"
											/>
										</h2>
										<p>Unable to find Dashboard for that date</p>
									</div>
								</DashboardStyles.ErrorWrapper>
							) : (
								<>
									<DashboardStyles.StatusWrapper>
										<h2>
											Research Prize
											<DashboardStyles.InfoIcon className="big_info">
												<img
													src="/images/gachaWeb/icon_INFO@2x.png"
													alt="info"
												/>
												<DashboardStyles.Infotext className="research-info-text">
													<p>
														The reward for each rank is determined by
														multiplying the reward pool
														<br /> for each currency by the reward for each
														rank’s corresponding distribution rate.
														<br />
														Please refer to the Overview for the distribution
														rate of each rank.
													</p>
												</DashboardStyles.Infotext>
											</DashboardStyles.InfoIcon>
										</h2>

										<DashboardStyles.Total>
											<li>
												<div>
													<span>
														<img
															src="/images/gachaWeb/icon_sd_A@3x.png"
															alt="total rewards"
														/>
													</span>
													<h4>Total SD rewards</h4>

													<p>
														{takeComma(
															roundTimeQuery.data.research_prize_response
																.starrydust_distribution_amount,
														)}
													</p>
												</div>
												<div className="sd-reward-pool">
													<h4>
														SD Reward Pool
														<DashboardStyles.InfoIcon>
															<img
																src="/images/gachaWeb/icon_INFO@2x.png"
																alt="info"
															/>
															<DashboardStyles.Infotext className="sd-info-text">
																<h3>
																	Last week&#39;s Gacha sales <br />
																	{takeComma(
																		roundTimeQuery.data.research_prize_response
																			.starrydust_base_amount,
																	)}
																	<span>+</span>
																	Rewards carried over <br />
																	{takeComma(
																		roundTimeQuery.data.research_prize_response
																			.starrydust_carried_over,
																	)}
																</h3>
																<p>
																	There is a minimum reward for each rank,
																	<br /> so the total rewards may differ from
																	the reward pool.
																	<br />
																	Gacha sales include $SD revenue and the amount
																	of <br />
																	$SD converted from CC revenue.
																</p>
															</DashboardStyles.Infotext>
														</DashboardStyles.InfoIcon>
													</h4>
													<p>
														{takeComma(
															roundTimeQuery.data.research_prize_response
																.starrydust_reward_pool,
														)}
													</p>
												</div>
											</li>
											<li>
												<div>
													<span>
														<img
															src="/images/gachaWeb/icon_sd_B@3x.png"
															alt="total rewards"
														/>
													</span>
													<h4>Total GP rewards</h4>
													<p>
														{takeComma(
															roundTimeQuery.data.research_prize_response
																.grandpa_distribution_amount,
														)}
													</p>
												</div>
												<div className="gp-reward-pool">
													<h4>
														GP Reward Pool
														<DashboardStyles.InfoIcon>
															<img
																src="/images/gachaWeb/icon_INFO@2x.png"
																alt="info"
															/>
															<DashboardStyles.InfotextSecond className="gp-info-text">
																<h3>
																	Last week&#39;s GP gacha sales
																	<br />
																	{takeComma(
																		roundTimeQuery.data.research_prize_response
																			.grandpa_base_amount,
																	)}
																</h3>

																<p>
																	GP spent on gacha is configured only from
																	newly generated ones rewarded through play,
																	<br />
																	excluding the Digital Data Research reward.
																	<br />
																	There is a minimum and maximum reward for each
																	rank,
																	<br /> so the total rewards may differ from
																	the reward pool.
																</p>
															</DashboardStyles.InfotextSecond>
														</DashboardStyles.InfoIcon>
													</h4>

													<p>
														{takeComma(
															roundTimeQuery.data.research_prize_response
																.grandpa_reward_pool,
														)}
													</p>
												</div>
											</li>
										</DashboardStyles.Total>
										<DashboardStyles.ResearchPrizeRank>
											<tbody>
												{roundTimeQuery.data.research_prize_response
													.reward_pool_list &&
													roundTimeQuery.data.research_prize_response
														.reward_pool_list.length > 0 &&
													roundTimeQuery.data.research_prize_response.reward_pool_list.map(
														(items_list) => (
															<tr key={items_list.rank}>
																<td>
																	<h5>{items_list.rank_tier}</h5>
																</td>
																{items_list.items.map((item) => (
																	<td key={item.order}>
																		<span>
																			<img
																				src={`/images/gachaWeb/icon_${item.item_name}.png`}
																				alt={item.item_name}
																			/>
																		</span>
																		<p>{takeComma(item.amount)}</p>
																	</td>
																))}
																{/* <td>
																	<span>
																		<img
																			src={`/images/gachaWeb/icon_Ticket.png`}
																			alt="icon_Ticket"
																		/>
																	</span>
																	<p>999999</p>
																</td> */}
															</tr>
														),
													)}
											</tbody>
										</DashboardStyles.ResearchPrizeRank>
									</DashboardStyles.StatusWrapper>
									<DashboardStyles.StatusWrapper>
										<h2>Leaderboard</h2>
										<DashboardStyles.SearchForm>
											<h3>Search</h3>
											<RadioGroup
												value={userSearchType}
												onChange={handleSearchNameButton}
											>
												<FormControlLabel
													value="1"
													control={<Radio />}
													label="User Name"
												/>
												<FormControlLabel
													value="2"
													control={<Radio />}
													label="ID"
												/>
											</RadioGroup>
											<DashboardStyles.InputWrapper>
												<input
													type="text"
													name="name"
													value={userInputValue}
													onChange={(e) => setUserInputValue(e.target.value)}
												/>
												<button type="submit" onClick={handleSearchValue}>
													<span>
														<img
															src="/images/gachaWeb/search_icon.png"
															alt="search"
														/>
													</span>
												</button>
											</DashboardStyles.InputWrapper>
										</DashboardStyles.SearchForm>
										<DashboardStyles.UserInfoTable>
											<DashboardStyles.UserTableWrapper>
												<DashboardStyles.UserRank>
													<thead>
														<tr>
															<th>Rank</th>
															<th>Thumb</th>
															<th>User Name</th>
															<th>ID</th>
															<th>Level</th>
															<th>Total Score</th>
															<th>Total Data</th>
															<th></th>
														</tr>
													</thead>
												</DashboardStyles.UserRank>
												<DashboardStyles.UserRankBody
													ref={userParentsRef}
													scroll={
														!userInfoQuery.isLoading &&
														!userInfoQuery.data?.rank_user_info_list
															? 'hidden'
															: 'scroll'
													}
												>
													<tbody ref={userChildRef}>
														{userInfoQuery.isLoading ? (
															<DashboardStyles.UserRankLodingWrapper>
																<Oval
																	height={67}
																	width={67}
																	color="#000"
																	wrapperStyle={{}}
																	wrapperClass=""
																	visible={true}
																	ariaLabel="oval-loading"
																	secondaryColor="#888"
																	strokeWidth={5}
																	strokeWidthSecondary={5}
																/>
																<h2>Loading data...</h2>
															</DashboardStyles.UserRankLodingWrapper>
														) : userInfoQuery.isError ||
														  userInfoQuery.data.from < 0 ||
														  !userInfoQuery.data.rank_user_info_list ? (
															<DashboardStyles.ErrorTDWrapper>
																<td>
																	<h2>
																		<img
																			src="/images/gachaWeb/icon_nodata@3x.png"
																			alt="nodata"
																		/>
																	</h2>
																	<p>
																		Not in the rankings.
																		<br /> Do your best!
																	</p>
																</td>
															</DashboardStyles.ErrorTDWrapper>
														) : (
															<>
																{userInfoQuery.data.rank_user_info_list.map(
																	(user_list, index) => (
																		<tr key={index}>
																			<td>{user_list.rank}</td>
																			<td>
																				<DashboardStyles.InfoImage>
																					<img
																						src={user_list.thumb}
																						alt="rank info"
																					/>
																				</DashboardStyles.InfoImage>
																			</td>
																			<td>{user_list.user_name}</td>
																			<td>{user_list.my_code}</td>
																			<td>{user_list.level}</td>
																			<td>
																				{takeComma(user_list.total_score)}
																			</td>
																			<td>{user_list.coordi_count}</td>
																			<td></td>
																		</tr>
																	),
																)}
															</>
														)}
													</tbody>
												</DashboardStyles.UserRankBody>
											</DashboardStyles.UserTableWrapper>
											{userInfoQuery.isLoading ? (
												''
											) : userInfoQuery.isError ||
											  userInfoQuery.data.from < 0 ||
											  !userInfoQuery.data.rank_user_info_list ? (
												''
											) : (
												<DashboardStyles.ScrollWrapper>
													<button onClick={handleUserScrollTop}>
														<img
															src="/images/gachaWeb/arrow_bottom@3x.png"
															alt="scroll top"
														/>
													</button>
													<button onClick={handleUserScrollBottom}>
														<img
															src="/images/gachaWeb/arrow_bottom@3x.png"
															alt="scroll bottom"
														/>
													</button>
												</DashboardStyles.ScrollWrapper>
											)}
										</DashboardStyles.UserInfoTable>
										{userInfoQuery.isLoading ? (
											''
										) : userInfoQuery.isError ||
										  userInfoQuery.data.from < 0 ||
										  !userInfoQuery.data.rank_user_info_list ? (
											''
										) : (
											<DashboardStyles.PageNationWrapper onClick={scrollToTop}>
												<button
													onClick={() =>
														setUserSearchValue({
															...userSearchValue,
															paging_number: 1,
														})
													}
													disabled={userSearchValue.paging_number === 1}
												>
													<FirstArrow />
												</button>
												<button
													onClick={() =>
														setUserSearchValue((prev) => {
															return {
																...userSearchValue,
																paging_number: Math.max(
																	prev.paging_number - 1,
																	0,
																),
															};
														})
													}
													disabled={userSearchValue.paging_number === 1}
												>
													<PrevArrow />
												</button>
												<div>
													{!userInfoQuery.isLoading &&
														!userInfoQuery.isError &&
														new Array(
															Math.ceil(
																userInfoQuery.data.total_count /
																	userInfoQuery.data.limit,
															),
														)
															.fill(undefined)
															.map((v, i) => (
																<button
																	key={i}
																	onClick={() =>
																		setUserSearchValue({
																			...userSearchValue,
																			paging_number: i + 1,
																		})
																	}
																	className={
																		userSearchValue.paging_number === i + 1
																			? 'active'
																			: ''
																	}
																>
																	{i + 1}
																</button>
															))}
												</div>
												<button
													onClick={() =>
														setUserSearchValue((prev) => {
															return {
																...userSearchValue,
																paging_number: Math.max(
																	prev.paging_number + 1,
																	0,
																),
															};
														})
													}
													disabled={
														userSearchValue.paging_number ===
														Math.ceil(
															userInfoQuery.data.total_count /
																userInfoQuery.data.limit,
														)
													}
												>
													<NextArrow />
												</button>
												<button
													onClick={() =>
														setUserSearchValue({
															...userSearchValue,
															paging_number: Math.ceil(
																userInfoQuery.data.total_count /
																	userInfoQuery.data.limit,
															),
														})
													}
													disabled={
														userSearchValue.paging_number ===
														Math.ceil(
															userInfoQuery.data.total_count /
																userInfoQuery.data.limit,
														)
													}
												>
													<LastArrow />
												</button>
											</DashboardStyles.PageNationWrapper>
										)}
									</DashboardStyles.StatusWrapper>
								</>
							)}
						</>
					)}
				</DashboardStyles.WidthFixSize>
			</DashboardStyles.StatusContainer>
		</DashboardStyles.Main>
	);
};

export default Dashboard;
