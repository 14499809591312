import React, { useMemo } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import 'swiper/swiper.min.css';

import SnsLink from '../SNSLink/SnsLink';

import {
	EventTextWrapper,
	TextWrap,
	Wrapper,
	Inner,
	LivlyBg,
	LogoWrap,
	SwiperWrapper,
	DownLoadImageWrapper,
	VideoButton,
	PlayNowWrapper,
} from './Home.styles';

import { MobileModalApp } from '@/components/common/Modal/Modal';
// import ShowEventModal from '@/components/common/Modal/ShowEventModal';
// import { useEventModal } from '@/Hooks/useEventModal';

SwiperCore.use([Pagination]);

const Home: React.VFC = () => {
	const settings = useMemo<Swiper>(
		() => ({
			pagination: { clickable: true },
			loop: true,
		}),
		[],
	);

	// const nftEventPopup = useEventModal('nftPopUpNotShow');

	return (
		<Wrapper className="section" id="scroll-home">
			<Inner>
				<LogoWrap>
					<img
						src="/images/common/logo_m.png"
						width={263}
						height={63}
						alt="metalivly"
					/>
				</LogoWrap>
				<SwiperWrapper>
					<Swiper {...settings}>
						<SwiperSlide>
							<EventTextWrapper>
								<PlayNowWrapper>
									<img
										src="/images/picture/PLAYNOW_web@3x.png"
										alt="play now"
									/>
								</PlayNowWrapper>

								<DownLoadImageWrapper>
									<div>
										<a
											href="https://cocone.app/dHq"
											target="_blank"
											rel="noreferrer"
										>
											<img
												src="/images/button/download_on_the_app_store.png"
												alt="ios download"
											/>
										</a>
									</div>
									<div>
										<a
											href="https://cocone.app/dHb"
											target="_blank"
											rel="noreferrer"
										>
											<img
												src="/images/button/get_it_on_google_play.png"
												alt="google download"
											/>
										</a>
									</div>
								</DownLoadImageWrapper>
							</EventTextWrapper>
						</SwiperSlide>
						<SwiperSlide>
							<TextWrap>
								<p>
									Livlies are incredibly unique and diverse creatures <br />
									that eat bugs and poop out jewels. <br />
									<br />
									First discovered through the practice of alchemy, <br />
									livlies were first founded somewhere in Europe <br />
									back in the medieval times. Unfortunately, the livlies <br />
									went extinct before the world got to know them <br />
									due to the ongoing wars. <br />
									<br />
									As the years went by, a biologist named Dr. Muller was <br />
									able to find a way to revive the extinct livlies and <br />
									founded the Livly Reboot Institute in Japan as a way <br />
									to study the creatures. Through consistent research, <br />
									hom and islands were created by extracting cells <br />
									from livlies and were distributed for <br />
									people to use and experiment. <br />
									<br />
									We digitize the livlies, homs, and islands and <br />
									present them in the virtual world of Meta Livly!,
								</p>
							</TextWrap>
						</SwiperSlide>
						<SwiperSlide>
							<TextWrap>
								<p>
									One of the first avatar coordinating P2E games, <br />
									Meta Livly implements blockchain technology to <br />
									create the cutest way to earn currency. <br />
									<br />
									Care for your digital livly pet by taking on the <br />
									form of an online extension of yourself called a
									&#34;hom&rdquo;,
									<br />
									customize your hom and island to the style you want, <br />
									and collect Starry Dust to earn currency!
								</p>
							</TextWrap>
						</SwiperSlide>
					</Swiper>
				</SwiperWrapper>
				<VideoButton>
					<MobileModalApp />
				</VideoButton>
				<SnsLink />
				<LivlyBg>
					<img src="/images/picture/home_cha.png" alt="" />
				</LivlyBg>
			</Inner>

			{/* <ShowEventModal
				isModalOpen={nftEventPopup.isModalOpen}
				handleCloseModal={nftEventPopup.handleCloseModal}
				width={86}
				height={133}
				fontSize={1.8}
				padding={4}
				imageHeight={127}
				buttonSize={{ width: 58, height: 12 }}
				buttonPosition={13}
				buttonHeight={5}
				image="Mobile_HomePage_NFT_PopUp(non-optimized)@3x"
				checked={nftEventPopup.checked}
				setChecked={() => nftEventPopup.setChecked(!nftEventPopup.checked)}
			/> */}
		</Wrapper>
	);
};

export default Home;
