import React from 'react';

import { Wrapper } from './QrModal.styles';

import StoreModal from '@/components/common/Modal/StoreModal';

interface IProps {
	isModalOpen: boolean;
	handleCloseModal: () => void;
	size: number;
	modalNode: {
		storeName: string;
		image: string;
	};
}

const QrModal: React.FC<IProps> = ({
	isModalOpen,
	handleCloseModal,
	size,
	modalNode,
}) => {
	return (
		<StoreModal
			isModalOpen={isModalOpen}
			handleCloseModal={handleCloseModal}
			size={size}
		>
			<Wrapper>
				<h2>{modalNode.storeName}</h2>
				<div>
					<img src={`/images/gachaWeb/${modalNode.image}.png`} alt="QR code" />
				</div>
				<p>
					You can easily download Meta Livly
					<br /> by scanning QR code.
				</p>
			</Wrapper>
		</StoreModal>
	);
};

export default QrModal;
