export const ServiceSlide = [
	{
		image: '',
		text: ``,
	},
	{
		image: '/images/picture/service_slide01.png',
		text: `The first avatar coordinating P2E service on the market, 
		Meta Livly uses a token based economy and implements NFTs using blockchain technology.`,
	},
	{
		image: '/images/picture/service_slide02.png',
		text: `Select from a wide variety of livlies to take care of and welcome them into your digital family.
		Livlies can be transformed into different species later on.`,
	},
	{
		image: '/images/picture/service_slide03.png',
		text: `Livlies change colors according to what kind of bug they're fed,
		so try experimenting to customize your livly's color!`,
	},
	{
		image: '/images/picture/service_slide04.png',
		text: `When livlies are well taken care of, they'll excrete jewels called doodoo.`,
	},
	{
		image: '/images/picture/service_slide05.png',
		text: `Exchange your livly's doodoo for GP to spin gachas and gain unique items.`,
	},
	{
		image: '/images/picture/service_slide06.png',
		text: `Customize your island and your hom's fashion 
		with the various items that you get from gachas.`,
	},
	{
		image: '/images/picture/service_slide07.png',
		text: `The more fashion and island decoration items you get, 
		the more Starry Dust (SD) you'll be able to collect. 
		SD is a currency used specifically in Meta Livly, 
		but can be exported to your wallet to cash into other currencies.`,
	},
	{
		image: '/images/picture/service_slide08.png',
		text: `Visit other islands to make friends, water trees, and enjoy a relaxing walk with your livly!`,
	},
];
