import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { Wrapper, Button, Arrow, Contents } from './Accordion.styles';

interface IProps {
	title: string;
	children: ReactNode;
}

const Accordion: React.FC<IProps> = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const parentsRef = useRef<HTMLDivElement>(null);
	const childRef = useRef<HTMLDivElement>(null);

	const handleOnAccordion = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();

			if (!parentsRef.current || !childRef.current) {
				return;
			}

			if (parentsRef.current.clientHeight > 0) {
				parentsRef.current.style.height = '0px';
			} else {
				parentsRef.current.style.height = `${childRef.current.clientHeight}px`;
			}

			setIsOpen(!isOpen);
		},
		[isOpen],
	);

	return (
		<Wrapper>
			<Button onClick={handleOnAccordion} isOpen={isOpen}>
				<div>
					{title}
					<Arrow isOpen={isOpen} className="material-symbols-outlined">
						change_history
					</Arrow>
				</div>
			</Button>
			<Contents ref={parentsRef}>
				<div ref={childRef}>{children}</div>
			</Contents>
		</Wrapper>
	);
};

export default Accordion;
