import React from 'react';

import ScrollIntoView from 'react-scroll-into-view';

// import SnsLink from '../SNSLink/SnsLink';

import {
	Wrapper,
	LogoWrap,
	// SnsLinkLabel,
	// SnsLinkWrap,
	Copyright,
} from './Footer.styles';

const Footer: React.VFC = () => {
	return (
		<Wrapper className="section fp-auto-height">
			<ScrollIntoView selector={`#scroll-home`}>
				<LogoWrap>
					<img
						src="/images/common/logo_footer_m.png"
						width={157}
						height={37}
						alt="Metalivly"
					/>
				</LogoWrap>
				<Copyright>
					©2022. Cocone M Corporation ©2021. cocone corporation. All Rights
					Reserved.
				</Copyright>
			</ScrollIntoView>

			{/* <SnsLinkLabel>JOIN OUR COMMUNITY</SnsLinkLabel>
			<SnsLinkWrap>
				<SnsLink />
			</SnsLinkWrap> */}
		</Wrapper>
	);
};

export default Footer;
