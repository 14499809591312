import styled from '@emotion/styled';

import {
	InnerWrap,
	Section,
	SwiperWrapLg2,
} from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/home_bg.png') 100% 50% no-repeat;
	background-size: cover;
	text-align: center;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const Inner = styled(InnerWrap)`
	height: auto;
	@media (max-height: 830px) {
		padding-top: 70px;
	}
`;

export const Title = styled.h3`
	position: relative;
	z-index: 1;
	// margin-top: 123px;
	padding-top: 60px;
	margin-bottom: 38px;
	img {
		width: 616px;
		height: 147px;
		object-fit: contain;
	}
`;

export const Contents = styled.div`
	margin-top: 50px;
	font-size: 16px;
	color: #fff;
	line-height: 30px;
	display: block;
	position: relative;
	z-index: 1;
	text-shadow: 0 1px 3px #724f9e;
`;

export const EventTextWrapper = styled.div`
	letter-spacing: 3.99px;
	color: #fff;
	font-stretch: normal;
	object-fit: contain;

	h2 {
		font-size: 100px;
		font-weight: bold;
		text-shadow: 0 1px 3px #724f9e;
	}

	p {
		font-size: 57px;
		font-weight: bold;
		text-shadow: 0 1px 3px #724f9e;
	}

	> span {
		display: inline-block;
		margin-top: 60px;
		vertical-align: sub;
		font-size: 30px;
		font-weight: bold;
		letter-spacing: normal;
		text-shadow: 0 1px 3px #724f9e;
	}

	> img {
		margin-left: 12px;
		vertical-align: middle;
	}
`;

export const DownLoadImageWrapper = styled.div`
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 23px;
	z-index: 5;

	> span {
		display: inline-block;
		width: 277px;

		&:first-of-type {
			margin-right: 23px;
		}

		a {
			display: inline-block;
			height: 100%;
			width: 100%;
		}
	}
`;

export const ButtonBlock = styled.div`
	position: relative;
	bottom: -80px;
	z-index: 1;

	a {
		width: 56px;
		height: 55px;
		display: inline-block;
		& + a {
			margin-left: 27px;
		}
	}

	div {
		// width: 262px;
		// height: 52px;
		// margin: 0 auto;
		// border-radius: 31px;
		// border: solid 2px #fff;
		// background-color: rgba(255, 255, 255, 0.1);

		span {
			width: 182px;
			height: 45px;
			margin: 0 40px 7px;
			object-fit: contain;
			font-family: SpoqaHanSans;
			font-size: 30px;
			font-weight: 100;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.77;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
		}
	}
`;

export const Image = styled.img`
	position: absolute;
	bottom: 0;
	&.left {
		width: 845px;
		height: 872px;
		left: 0;
	}
	&.right {
		width: 805px;
		height: 981px;
		right: 0;
	}
`;

export const SwiperWrapper = styled(SwiperWrapLg2)`
	text-align: center;
	background: none;

	.swiper-container {
		height: 620px;
		overflow: inherit !important;

		.swiper-pagination {
			bottom: -50px;
		}
	}
`;

export const ModalWrapper = styled.div`
	&.modal-wrapper-photo {
		width: 755.4px;
		height: 620px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
		margin: 0 19px 0 14px;
		padding: 0;
	}
`;

export const ModalTitle = styled.h1`
	display: block;
	margin: 0 auto;
	margin-bottom: 39px;
	font-size: 62px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: -0.62px;
	text-align: center;
	color: #111;

	&.modal-title-photo {
		width: 111px;
		height: 26px;
		margin: 0 626px 0 0;
		font-size: 18px;
		line-height: 1.11;
		letter-spacing: -0.18px;
		text-align: left;
	}
`;

export const PlayNowWrapper = styled.div`
	position: absolute;
	width: 980px;
	top: -100px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;

	img {
		width: 100%;
	}
`;
