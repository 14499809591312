/* eslint-disable react/no-unescaped-entities */

import React from 'react';

import {
	Article,
	Contents,
	Inner,
	TextBlock,
	Title,
	Image,
} from './PlayToEarn.styles';

const PlayToEarn: React.VFC = () => {
	return (
		<Article className="section">
			<Inner>
				<TextBlock>
					<Title>
						CCP to Earn
						<span>(Coordinate to Earn)</span>
					</Title>
					<Contents>
						Collect the doodoo from your cute digital livly pets
						<br />
						to exchange them for GP and for different fashion
						<br />
						and island items through gachas.
						<br />
						<br />
						The more you decorate and customize your hom
						<br />
						and island, the better your chances to earn SD are.
						<br />
						<br />
						All of your digital assets can be minted into NFTs
						<br />
						to trade and to cash in at the marketplace!
						<br />
						<br />
						<span>*Details are subject to change.</span>
					</Contents>
				</TextBlock>
				<Image src="/images/picture/play_item_3.png" alt="play" />
			</Inner>
		</Article>
	);
};

export default PlayToEarn;
