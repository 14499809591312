import React from 'react';

import {
	ThumbnailWrap,
	Thumbnail,
	TextWrap,
	Title,
	TransparentLink,
} from './NFTsItem.styles';

import {
	MobileSection,
	NoWrap,
	SectionInner,
} from '@/assets/styles/Common.styles';

const NFTsItem: React.VFC = () => {
	return (
		<MobileSection
			imageUrl="/images/picture/nfts_bg_m.png"
			id="scroll-nft_items"
			className="section"
		>
			<SectionInner>
				<Title>NFT Items</Title>
				<ThumbnailWrap>
					<Thumbnail src="/images/picture/nfts_item_m.png" alt="nfts" />
				</ThumbnailWrap>

				<TransparentLink
					href="https://cocone.app/dxC"
					target="_blank"
					rel="noreferrer"
				>
					Visit the <strong>marketplace &gt;</strong>
				</TransparentLink>
				<TextWrap>
					<p>
						Livlies, clothes, accessories, and island
						<br />
						decoration items that you collect throughout <br />
						your Meta Livly playthrough are recognized <br />
						as your own assets.
						<br />
					</p>
					<p>
						These assets can be held as NFTs or traded <br />
						with other players through the NFT marketplace.
						<br /> Use Meta Livly to gain access to valuable NFTs
						<br /> and earn some extra cash!
					</p>

					<p>
						<strong>● Livly</strong> <br />
						<span>
							There are a variety of cute and loving <br />
							livly species to choose from! <br />
							The same livly species are customizable <br />
							with different colors so try <br />
							experimenting with different colors when <br />
							sending them out as NFTs.
						</span>
					</p>
					<p>
						<strong>● Hom </strong>
						<br />
						<span>
							<NoWrap> Secure hom NFTs that are equipped with a</NoWrap>
							<br />
							combination of stylist and diverse items! <br />
						</span>
					</p>
					<p>
						<strong>● Island </strong>
						<br />
						<span>
							Secure island NFTs with trees that bear fruit that
							<br /> can be used to transform livlies in the future!
						</span>
					</p>
				</TextWrap>
			</SectionInner>
		</MobileSection>
	);
};

export default NFTsItem;
