import { atom } from 'recoil';

export const displayBubbleState = atom<boolean>({
	key: 'displayBubble',
	default: true,
});

export const actionTimeState = atom<number>({
	key: 'actionTime',
	default: new Date().getTime(),
});
