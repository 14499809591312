import styled from '@emotion/styled';

export const Wrapper = styled.table`
	border: 1px solid #fff;
	border-collapse: collapse;
	font-size: 20px;
	thead {
		th {
			padding: 10px 50px;
			background: #6e6e6e;

			span {
				font-size: 18px;
				font-weight: 100;
			}
		}
	}

	th,
	td {
		padding: 10px;
		border: 1px solid #fff;
	}
	tr {
		text-align: center;
	}
`;
