import React, { useMemo, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import 'swiper/swiper.min.css';

// import ShowEventModal from '../../common/Modal/ShowEventModal';

import {
	Article,
	Inner,
	ButtonBlock,
	Contents,
	Image,
	SwiperWrapper,
	EventTextWrapper,
	DownLoadImageWrapper,
	PlayNowWrapper,
} from './Home.styles';

import { ModalApp } from '@/components/common/Modal/Modal';
// import { useEventModal } from '@/Hooks/useEventModal';

SwiperCore.use([Pagination]);

const Home: React.VFC = () => {
	const [, setIsSwiper] = useState<SwiperCore>();

	const settings = useMemo<Swiper>(
		() => ({
			pagination: { clickable: true },
			loop: true,
			onSwiper: (swiper) => {
				setIsSwiper(swiper);
			},
		}),
		[],
	);

	// const nftEventPopup = useEventModal('nftPopUpNotShow');

	return (
		<Article className="section video-wrap">
			<Inner>
				<SwiperWrapper>
					<Swiper {...settings}>
						<SwiperSlide>
							<EventTextWrapper>
								<PlayNowWrapper>
									<img
										src="/images/picture/PLAYNOW_web@3x.png"
										alt="play now"
									/>
								</PlayNowWrapper>

								<DownLoadImageWrapper>
									<span>
										<a
											href="https://cocone.app/dHq"
											target="_blank"
											rel="noreferrer"
										>
											<img
												src="/images/button/download_on_the_app_store.png"
												alt="ios download"
											/>
										</a>
									</span>
									<span>
										<a
											href="https://cocone.app/dHb"
											target="_blank"
											rel="noreferrer"
										>
											<img
												src="/images/button/get_it_on_google_play.png"
												alt="google download"
											/>
										</a>
									</span>
								</DownLoadImageWrapper>
							</EventTextWrapper>
						</SwiperSlide>
						<SwiperSlide>
							<Contents>
								Livlies are incredibly unique and diverse creatures that eat
								bugs and poop out jewels.
								<br />
								First discovered through the practice of alchemy, livlies were
								first founded somewhere in Europe back in the medieval times.
								<br />
								Unfortunately, the livlies went extinct before the world got to
								know them due to the ongoing wars.
								<br />
								As the years went by, a biologist named Dr. Muller was able to
								find a way to revive the extinct livlies and founded the
								<br />
								Livly Reboot Institute in Japan as a way to study the creatures.
								<br />
								Through consistent research, hom and islands were created by
								extracting cells from livlies and were
								<br />
								distributed for people to use and experiment.
								<br />
								<br />
								We digitize the livlies, homs, and islands and present them in
								the virtual world of Meta Livly!
								<br />
								<br />
								One of the first avatar coordinating P2E games, Meta Livly
								implements blockchain technology to
								<br />
								create the cutest way to earn currency.
								<br />
								Care for your digital livly pet by taking on the form of an
								online extension of yourself called a &#34; hom&#34;,
								<br />
								customize your hom and island to the style you want, and collect
								Starry Dust to earn currency!
							</Contents>
						</SwiperSlide>
					</Swiper>
				</SwiperWrapper>
				<ButtonBlock>
					<ModalApp open={false} mute={true} />
				</ButtonBlock>
				{/* <ShowEventModal
					isModalOpen={nftEventPopup.isModalOpen}
					handleCloseModal={nftEventPopup.handleCloseModal}
					width={81}
					height={93}
					fontSize={1.8}
					padding={2}
					imageHeight={848}
					buttonSize={{ width: 36, height: 7 }}
					buttonPosition={11}
					buttonHeight={5}
					checked={nftEventPopup.checked}
					image="02_HomePage_NFT_PopUp@3x"
					setChecked={() => nftEventPopup.setChecked(!nftEventPopup.checked)}
				/> */}
			</Inner>
			<Image
				src="/images/picture/home_left.png"
				alt="메타 리브리"
				className="left"
			/>
			<Image
				src="/images/picture/home_right.png"
				alt="메타 리브리"
				className="right"
			/>
		</Article>
	);
};

export default Home;
