export default function takeComma(moneyName: string) {
	// Number 형으로 변환
	const numberMoneyName = Number(moneyName);

	// 소수점 3자리 이후는 버려
	const result = Math.floor(numberMoneyName * 1000) / 1000;

	// Locale 에 맞춰서 숫자표기 변환
	return result.toLocaleString();
	// return moneyName.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}
