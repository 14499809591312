import styled from '@emotion/styled';

import { InnerWrap, Section } from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/tp_bg.png') 50% 50% no-repeat;
	background-size: cover;
`;

export const Inner = styled(InnerWrap)`
	width: 1550px;
	height: 506px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TextBlock = styled.div`
	align-self: flex-start;
`;

export const Title = styled.h3`
	font-size: 55px;
	color: #fff;
	font-weight: bold;
	margin-bottom: 43px;
`;

export const Contents = styled.p`
	/* max-width: 564px; */
	font-size: 18px;
	color: #fff;
	line-height: 1.83;
	display: block;
`;

export const Image = styled.img`
	width: 844px;
	height: 620px;
`;
