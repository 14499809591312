import React, { useMemo, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import 'swiper/swiper.min.css';

import { Article, Contents, Inner, SwiperWrapper } from './Service.styles';
import { ServiceSlide } from './ServiceSlide';

SwiperCore.use([Pagination]);

const Service: React.VFC = () => {
	const [, setIsSwiper] = useState<SwiperCore>();
	const settings = useMemo<Swiper>(
		() => ({
			pagination: { clickable: true },
			loop: true,
			onSwiper: (swiper) => {
				setIsSwiper(swiper);
			},
		}),
		[],
	);

	return (
		<Article className="section">
			<Inner>
				<SwiperWrapper>
					<Swiper {...settings}>
						{ServiceSlide.map((item, index) => (
							<SwiperSlide key={index}>
								{item.image !== '' && <img src={item.image} alt="story" />}
								{item.image === '' && (
									<video data-autoplay loop muted style={{ height: 600 }}>
										<source src="/videos/metaLivly_play.mp4" type="video/mp4" />
									</video>
								)}
								{item.text !== '' && (
									<Contents className="text">{item.text}</Contents>
								)}
								{item.text === '' && (
									<Contents className="img-slide-scroll">
										<img
											src="/images/picture/service_slide_scroll.png"
											alt="slide_Scroll"
										/>
									</Contents>
								)}
							</SwiperSlide>
						))}
					</Swiper>
				</SwiperWrapper>
			</Inner>
		</Article>
	);
};

export default Service;
