import styled from '@emotion/styled';

export const Main = styled.main`
	overflow-y: hidden;
`;

export const DateWrapper = styled.div`
	background: #fff;
	> div {
		width: 1450px;
		margin: 0 auto;
		padding: 5px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-size: 25px;
			font-weight: bold;
		}

		.react-datepicker__tab-loop {
			position: absolute;
			top: 0;

			.react-datepicker__triangle {
				&::after,
				::before {
					left: -15px;
				}
			}

			.react-datepicker-popper {
				left: 750px !important;
				z-index: 9;
			}
		}

		.react-datepicker-wrapper {
			position: relative;
			display: inline-block;
			width: 1003px;
			z-index: 2;
			.react-datepicker__input-container {
				display: inline-block;

				input {
					width: 100%;
					height: 50px;
					border-radius: 12px;
					background: transparent;
					font-size: 20px;
					font-weight: bold;
					text-align: center;
				}
			}
		}

		> button {
			width: 219px;
			height: 50px;
			border-radius: 12px;
			background-color: #7dccba;
			text-align: center;
			color: #fff;
			font-size: 20px;
			font-weight: bold;
		}
	}
`;

export const RoundDisplayWrapper = styled.div`
	background-color: #3c3c3c;
	color: #fff;

	ul {
		width: 1450px;
		display: flex;
		padding: 11px 0;
		justify-content: space-between;
		margin: 0 auto;
		font-size: 12px;

		li {
			p {
				line-height: 2;
				font-weight: 100;
			}

			&:last-child {
				text-align: right;
			}
		}
	}
`;

export const StatusContainer = styled.section`
	height: 79vh;
	background-color: #d1d1d1;

	> div {
		height: 100%;
	}
`;

export const WidthFixSize = styled.div`
	width: 1450px;
	margin: 0 auto;
	justify-content: space-between;
	display: flex;
`;

export const StatusWrapper = styled.article`
	padding: 17px 42px;
	background: #fff;
	height: 100%;
	h2 {
		text-align: center;
		margin-bottom: 13px;
		font-size: 20px;

		span {
			position: relative;

			&:hover .research-info-text {
				position: absolute;
				top: 50px;
				right: -32px;
				display: block;
			}
		}
	}

	&:first-of-type {
		width: 38%;
	}

	&:last-of-type {
		width: 60%;
	}
`;

export const Total = styled.ul`
	display: flex;
	margin-bottom: 14px;

	li {
		width: 224px;
		height: 187px;
		border-radius: 10px;
		padding: 10px;
		background-color: #f3f3f3;
		text-align: center;
		margin-right: 10px;

		div {
			> span {
				display: inline-block;
				width: 55px;
				margin-bottom: 3px;
			}
			h4 {
				font-size: 14px;
				font-weight: bold;
			}

			> p {
				font-size: 18px;
				font-weight: bold;
				color: #7dccba;
			}

			&:last-of-type {
				padding-top: 13px;
				margin-top: 12px;
				border-top: 1px solid #d5d5d5;

				h4 {
					font-size: 14px;
					font-weight: normal;
					color: #646464;
				}

				> p {
					color: #646464;
				}
			}
		}

		&:last-of-type {
			margin: 0;
		}
	}

	.sd-reward-pool {
		span {
			position: relative;

			&:hover .sd-info-text {
				position: absolute;
				top: 40px;
				left: -175px;
				display: block;

				&::before {
					content: '';
					top: -20px;
					right: 305px;
				}
			}
		}
	}

	.gp-reward-pool {
		span {
			position: relative;

			&:hover .gp-info-text {
				position: absolute;
				top: 40px;
				left: -375px;
				display: block;

				&::before {
					content: '';
					top: -20px;
					right: 105px;
				}
			}
		}
	}
`;

export const ResearchPrizeRank = styled.table`
	border-collapse: collapse;
	tbody {
		display: inline-block;
		width: 435px;

		tr {
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid #d5d5d5;

			td {
				display: inline-block;
				width: 95px;
				padding: 2px 0;

				span {
					display: inline-block;
					width: 24px;
					margin-right: 10px;
					vertical-align: middle;
				}

				h5 {
					font-size: 12px;
					font-weight: bold;
					color: #7dccba;
				}

				p {
					display: inline-block;
					width: 60px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					vertical-align: bottom;
					vertical-align: middle;
					font-size: 12px;
					font-weight: bold;
					color: #826c3c;
				}

				&:first-of-type {
					width: 65px;
				}
			}
		}
	}
`;

export const SearchForm = styled.form`
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 22px;

	h3 {
		margin-right: 48px;
		font-size: 25px;
		font-weight: bold;
	}

	.MuiFormGroup-root {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;

		.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
			color: #000 !important;
		}

		.css-ahj2mt-MuiTypography-root {
			font-weight: 100;
		}
	}

	label {
		font-size: 20px;
		font-weight: 300;
		margin-right: 37px;
	}
	div {
		input[type='text'] {
			width: 80%;
			height: 100%;
			border-radius: 7px;
			font-size: 20px;
			padding: 10px;
			font-weight: normal;
		}

		button {
			width: 20%;
			span {
				display: inline-block;
				width: 30px;
			}
		}
	}
`;

export const UserRank = styled.table`
	width: 100%;
	border-collapse: collapse;

	thead {
		display: block;

		th {
			width: 100%;
			padding: 10px 0;
			font-size: 14px;
			font-weight: normal;
			border-bottom: 1px solid #5c5c5c;
			&:nth-of-type(8) {
				width: 240px;
				border-bottom: none;
			}
		}
	}
	tr {
		margin-top: 5.5px;
		display: flex;
		align-items: center;
		width: 100%;
		text-align: center;

		td {
			width: 100%;
			padding: 10px 0;
			font-size: 14px;
			font-weight: 300;
		}
	}

	tbody {
		display: block;
		transition: all 0.2s;
	}
`;

export const InputWrapper = styled.div`
	width: 285px;
	height: 53px;
	border-radius: 7px;
	border: solid 1px #000;
	display: flex;

	input:focus {
		outline: none;
	}
`;

export const InfoIcon = styled.span`
	display: inline-block;
	width: 16px;
	margin-left: 5px;
	vertical-align: middle;

	&.big_info {
		width: 23.2px;
		margin-left: 10px;
	}

	&:hover {
		.research-info-text {
			display: block;
		}
	}
`;

export const DatePickerWrapper = styled.div`
	position: relative;
	border-radius: 12px;
	background-color: #d1d1d1;

	> span {
		position: absolute;
		display: inline-block;
		right: 20px;
		top: 5px;
		width: 40px;
		z-index: 1;
	}
`;

export const Infotext = styled.summary`
	display: none;
	position: relative;
	width: 510px;
	border-radius: 5px;
	padding: 20px 10px 25px 10px;
	background-color: #000000eb;
	opacity: 0.8;
	color: #fff;
	text-align: center;

	h3 {
		font-size: 15px;
		font-weight: normal;
		margin-bottom: 12px;
		line-height: 1.5;
		span {
			display: block;
		}
	}

	p {
		font-size: 14px;
		font-weight: 100;
		line-height: 1.2;
	}

	&::before {
		position: absolute;
		top: -20px;
		right: 33px;
		content: '';
		width: 0px;
		height: 0px;
		border-top: 10px solid none;
		border-bottom: 20px solid #000000ed;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
	}
`;

export const InfotextSecond = styled.summary`
	display: none;
	position: relative;
	width: 580px;
	border-radius: 5px;
	padding: 28px 0;
	background-color: #000000eb;
	opacity: 0.8;
	color: #fff;
	text-align: center;

	h3 {
		font-size: 15px;
		font-weight: normal;
		margin-bottom: 12px;
		line-height: 1.5;
		span {
			display: block;
		}
	}

	p {
		font-size: 14px;
		font-weight: 100;
		line-height: 1.2;
	}

	&::before {
		position: absolute;
		top: -20px;
		left: 373px;
		/* right: 50px; */
		content: '';
		width: 0px;
		height: 0px;
		border-top: 10px solid none;
		border-bottom: 20px solid #000000ed;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
	}
`;

export const PageNationWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 44px;

	button {
		padding: 0 10px;
		font-size: 14px;
		font-weight: normal;

		&.active {
			font-weight: bold;
			color: #7dccba;
		}
	}
`;

export const InfoImage = styled.span`
	display: inline-block;
	width: 50px;
`;

export const UserRankBody = styled(UserRank)<{ scroll: string }>`
	z-index: 10;
	position: relative;
	display: block;
	margin-top: 20px;
	// height: 470px;
	height: 450px;
	overflow: ${(props) => props.scroll};

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-track:vertical {
		border-width: 1px;
		border-radius: 4px;
		background: #eee;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
	}

	translate: all 1s;
	tbody {
		tr {
			td {
				&:nth-of-type(8) {
					display: inline-block;
					width: 170px;
				}
			}
		}
	}
`;

export const ScrollWrapper = styled.div`
	position: absolute;
	top: -59px;
	right: -15px;
	display: flex;
	height: 540px;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 70px;

	button {
		width: 37px;
		height: 37px;
		opacity: 0.3;
		&:first-of-type {
			transform: rotate(180deg);
		}

		&:hover {
			opacity: 1;
			transition: opacity 0.2s;
		}
	}
`;

export const UserTableWrapper = styled.div`
	width: 100%;
`;

export const UserInfoTable = styled.div`
	position: relative;
`;

export const ErrorWrapper = styled.div`
	width: 85vw;
	height: 100vh;
	background: #fff;
	display: flex;
	justify-content: space-around;
	align-items: center;

	div {
		h2 {
			width: 67px;
			margin: 0 auto;
		}

		p {
			text-align: center;
		}
	}
`;

export const ErrorTDWrapper = styled.tr`
	background: #fff;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 60vh;

	td {
		h2 {
			width: 67px;
			margin: 0 auto;
		}

		p {
			font-weight: 100;
			text-align: center;
		}
	}
`;

export const LodingWrapper = styled(ErrorWrapper)`
	justify-content: center;
	flex-direction: column;

	h2 {
		margin-top: 19px;
		font-size: 18px;
		font-weight: 300;
	}
`;

export const UserRankLodingWrapper = styled(LodingWrapper)`
	justify-content: center;
	flex-direction: column;
	height: 60vh;

	h2 {
		margin-top: 19px;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
		margin: 0 auto;
	}
`;

export const AbbreviationWord = styled.span`
	display: inline-block;
	width: 900px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: bottom;
`;
