import React from 'react';

import { BrowserView } from 'react-device-detect';

import { Header, SNSLink } from '../pc';

import FullPage from '@/components/domain/FullPage';

export const anchors = [
	'Home',
	'Service',
	'Play_to_Earn',
	'NFT_Items',
	'SD',
	'Roadmap',
];

const FullpageWeb: React.VFC = () => {
	return (
		<>
			<BrowserView>
				<Header anchors={anchors} />
				<SNSLink />
			</BrowserView>
			<FullPage anchors={anchors} />
		</>
	);
};

export default FullpageWeb;
