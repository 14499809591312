import React, { useCallback, useMemo, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import 'swiper/swiper.min.css';

import {
	Contents,
	Inner,
	NextButton,
	PrevButton,
	SwiperWrapper,
	SlideImg,
	Title,
	Video,
} from './Service.styles';
import { ServiceSlide } from './ServiceSlide';

import { MobileSection, SectionInner } from '@/assets/styles/Common.styles';

SwiperCore.use([Pagination]);

const Story: React.VFC = () => {
	const [isSwiper, setIsSwiper] = useState<SwiperCore>();
	const settings = useMemo<Swiper>(
		() => ({
			pagination: { clickable: true },
			loop: true,
			onSwiper: (swiper) => {
				setIsSwiper(swiper);
			},
		}),
		[],
	);

	const onPrev = useCallback(() => {
		isSwiper?.slidePrev();
	}, [isSwiper]);

	const onNext = useCallback(() => {
		isSwiper?.slideNext();
	}, [isSwiper]);

	return (
		<MobileSection
			imageUrl="/images/picture/service_bg_m.png"
			className="section"
			id="scroll-service"
		>
			<SectionInner>
				<Title>Service</Title>
				<Inner>
					<SwiperWrapper>
						<Swiper {...settings}>
							{ServiceSlide.map((item, index) => (
								<SwiperSlide key={index}>
									{item.image !== '' && (
										<SlideImg src={item.image} alt="story" />
									)}
									{item.image === '' && (
										<Video>
											<video autoPlay loop muted style={{ height: 600 }}>
												<source
													src="/videos/metaLivly_play.mp4"
													type="video/mp4"
												/>
											</video>
										</Video>
									)}
									{item.text !== '' && <Contents>{item.text}</Contents>}
									{item.text === '' && (
										<Contents className="img-slide-scroll">
											<img
												src="/images/picture/service_slide_scroll.png"
												alt="slide_Scroll"
											/>
										</Contents>
									)}
								</SwiperSlide>
							))}
						</Swiper>
						<PrevButton onClick={onPrev} aria-label="prev">
							{/* <img
								src="/images/button/btn_left_blue.png"
								alt="prev"
								width={17}
								height={25}
							/> */}
						</PrevButton>
						<NextButton onClick={onNext} aria-label="next">
							{/* <img
								src="/images/button/btn_right_blue.png"
								alt="next"
								width={17}
								height={25}
							/> */}
						</NextButton>
					</SwiperWrapper>
				</Inner>
			</SectionInner>
		</MobileSection>
	);
};

export default Story;
