import styled from '@emotion/styled';

import {
	InnerWrap,
	Section,
	SwiperWrapLg,
} from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/service_bg.png') 50% 50% repeat;
	background-size: cover;
`;

export const Inner = styled(InnerWrap)`
	width: 1177px;
	height: auto;
`;

export const SwiperWrapper = styled(SwiperWrapLg)`
	width: 1155px;
	height: 813px;
	text-align: center;
	background: none;

	.swiper-container {
		height: 840px;
		margin-top: 40px;
	}
`;

export const Contents = styled.p`
	display: block;
	white-space: pre-line;

	&.text {
		font-size: 18px;
		color: #fff;
		margin-top: 100px;
		line-height: 1.83;
		text-align: center;
		padding: 0 26px;
	}

	&.img-slide-scroll {
		img {
			width: 108px;
			height: 94px;
			margin-top: 21px;
		}
	}
`;

export const PrevButton = styled.button`
	position: absolute;
	width: 82px;
	height: 91px;
	top: 50%;
	left: -110px;
	transform: translateY(-50%);
`;

export const NextButton = styled(PrevButton)`
	left: auto;
	right: -110px;
`;
