import styled from '@emotion/styled/macro';

export const ModalWrapper = styled.div<{ isVisible?: boolean }>`
	box-sizing: border-box;
	display: ${(props) => (props.isVisible ? 'block' : 'none')};
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	overflow: auto;
	outline: 0;

	&.modal-movie {
		width: 900px;
		height: 882px;
		margin: 99px 309px;
		object-fit: contain;
	}
`;

export const VideoWrapper = styled.div`
	video {
		vertical-align: bottom;
	}

	.close-btn {
		position: absolute;
		top: 21px;
		right: 22px;
		font-size: 33px;
		color: #fff;
		// background-color: #fff;
	}
`;

export const MobileVideoWrapper = styled.div`
	video {
		vertical-align: bottom;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.close-btn {
		position: absolute;
		top: 38px;
		right: 17px;
		font-size: 20px;
		color: #fff;
	}
`;
