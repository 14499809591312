import styled from '@emotion/styled';

export const Main = styled.main`
	background: #3c3c3c;
	color: #fff;
`;

export const Wrapper = styled.section`
	height: 100%;
	padding: 60px 0 230px 0;

	h2 {
		margin-bottom: 8px;
		font-size: 40px;
		font-weight: bold;
	}

	h3 {
		margin-bottom: 20px;
	}

	.show-menu {
		width: 376px;
		height: 500px;
		position: absolute;
		left: 0px;
		transition: 1s;
	}

	.hide-menu {
		width: 376px;
		height: 500px;
		position: absolute;
		left: -376px;
		transition: 1s;
	}

	.subTitleText {
		margin-bottom: 90px;
		line-height: 1.5;
	}

	.text-color-mint {
		font-weight: normal;
		color: #7dccba;
	}

	.table-explanation {
		padding: 10px 0;
		text-align: center;
		font-size: 15px;
		font-weight: 100;
	}
	.accordionGiven {
		width: 1510px;
	}
	.accordionPool {
		width: 1490px;
	}
	#sdRewardExplane {
		padding-left: 170px;
	}
`;

export const Title = styled.div`
	width: 1450px;
	margin: 29px auto;

	> div p {
		font-size: 20px;
	}
`;

export const ExplanationList = styled.ul`
	margin-top: 60px;
	li {
		margin-bottom: 80px;

		.indent-text {
			margin-left: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const TableWrapper = styled.table`
	margin-top: 40px;
	border: 1px solid #fff;
	border-collapse: collapse;
	font-size: 20px;
	text-align: center;
	padding: 10px;

	.title-cell {
		background: #6e6e6e;
		font-weight: 500;
	}

	td {
		padding: 10px 70px;
		border: 1px solid #fff;
	}
`;
