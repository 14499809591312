import React, { useState } from 'react';

import {
	Home,
	NFTsItem,
	PlayToEarn,
	Roadmap,
	Service,
	SD,
	Footer,
	Header,
	Menu,
} from '@/components/mobile';

const FullPageMobile: React.VFC = () => {
	const [visible, setVisible] = useState(false);

	const onClickMenu = () => {
		setVisible(false);
	};

	return (
		<section>
			<Header onClick={() => setVisible(true)} />
			<Menu
				visible={visible}
				onClickClose={() => setVisible(false)}
				onClickMenu={onClickMenu}
			/>
			<Home />
			<Service />
			<PlayToEarn />
			<NFTsItem />
			<SD />
			<Roadmap />
			<Footer />
		</section>
	);
};

export default FullPageMobile;
