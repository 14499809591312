import styled from '@emotion/styled';

import { InnerWrap, Section } from '@/assets/styles/Common.styles';

export const Article = styled(Section)`
	background: url('/images/picture/play_bg.png') 50% 50% no-repeat;
	background-size: cover;
`;

export const Inner = styled(InnerWrap)`
	width: 1514px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 488px;
`;

export const TextBlock = styled.div`
	align-self: flex-start;
`;

export const Title = styled.h3`
	font-size: 55px;
	color: #fff;
	font-weight: bold;
	margin-bottom: 43px;
	span {
		font-size: 25px;
		font-weight: normal;
		display: block;
		margin-top: 10px;
	}
`;

export const Contents = styled.p`
	max-width: 438px;
	font-size: 18px;
	color: #fff;
	line-height: 1.83;
	display: block;
	span {
		font-weight: lighter;
	}
`;

export const Image = styled.img`
	width: 1016px;
	height: auto;
`;
