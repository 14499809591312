import styled from '@emotion/styled';

export const SnsLinkWrap = styled.ul`
	display: flex;
	list-style: none;
	position: absolute;
	// position: fixed;
	right: 40px;
	bottom: 30px;
	z-index: 100;

	li {
		margin-bottom: 10px;
`;

export const SnsAnchor = styled.a``;

export const SnsImg = styled.img``;

export const BubbleBlock = styled.div`
	margin-right: 2px;
	.bubble {
		position: relative;
		top: 13px;
	}

	.text {
		position: absolute;
		left: 10%;
		top: 22px;
		font-weight: bold;
		font-size: 15px;
	}
`;
