import React from 'react';

import ReactFullpage from '@fullpage/react-fullpage';
import {
	BrowserView,
	MobileView,
	// TabletView
} from 'react-device-detect';
import { useSetRecoilState } from 'recoil';

// eslint-disable-next-line import/order
import { FullPageMobile, FullPagePc } from '../containers';

import { Footer } from '../pc';

import { anchorState } from '@/state/anchor';
import { displayBubbleState /** actionTimeState **/ } from '@/state/linkState';

const pluginWrapper = () => {
	require('fullpage.js/vendors/scrolloverflow');
};
interface IProps {
	anchors: string[];
}

const FullPage: React.VFC<IProps> = ({ anchors }) => {
	const setAnchor = useSetRecoilState(anchorState);
	const setDisplayBubble = useSetRecoilState(displayBubbleState);

	return (
		<>
			<BrowserView>
				<ReactFullpage
					pluginWrapper={pluginWrapper}
					anchors={anchors}
					licenseKey="210A37E7-68B84D3C-B2086FE2-F2DAC317"
					scrollOverflow
					afterLoad={(origin, destination) => {
						setAnchor(destination.anchor);
					}}
					onLeave={() => {
						setDisplayBubble(false);
					}}
					render={() => {
						return (
							<>
								<FullPagePc />
								<Footer />
							</>
						);
					}}
				/>
			</BrowserView>
			<MobileView>
				<FullPageMobile />
			</MobileView>
			{/* <TabletView /> */}
		</>
	);
};

export default FullPage;
