import React, { ReactNode } from 'react';

import ReactModal from 'react-modal';

import { CloseButton } from './StoreModal.styles';

interface IProps {
	isModalOpen: boolean;
	handleCloseModal: () => void;
	size: number;
	children: ReactNode;
}

const StoreModal: React.FC<IProps> = ({
	isModalOpen,
	handleCloseModal,
	size,
	children,
}) => {
	return (
		<ReactModal
			isOpen={isModalOpen}
			onRequestClose={handleCloseModal}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'rgba(0, 0, 0, 0.7)',
					zIndex: '10000',
				},
				content: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%,-50%)',
					width: `${size}px`,
					height: `${size}px`,
					backgroundColor: 'white',
				},
			}}
			ariaHideApp={false}
		>
			{children}
			<CloseButton onClick={handleCloseModal}>Close</CloseButton>
		</ReactModal>
	);
};

export default StoreModal;
