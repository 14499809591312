import styled from '@emotion/styled';

export const Wrapper = styled.div`
	text-align: center;
	background: url('/images/picture/home_bg_m.png') no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 100vh;
	height: auto;
	position: relative;
`;

export const Inner = styled.div`
	padding: 60px 0 0 0;
`;

export const LogoWrap = styled.div`
	margin: 0 auto 24px;
`;

export const VideoButton = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 116px;
	height: 23px;
	border-radius: 31px;
	border: solid 1px #fff;
	background-color: rgba(255, 255, 255, 0.1);
	font-size: 13px;
	font-weight: 100;
	line-height: 1.73;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	z-index: 1;
`;

export const LivlyBg = styled.div`
	position: absolute;
	bottom: 0;
	text-align: center;
`;

export const ThumbnailWrap = styled.div`
	margin: 10px 0 15px 0;
`;

export const TextWrap = styled.div`
	margin: 0 auto;
	margin-bottom: 24px;
	p {
		font-size: 12px;
		margin-bottom: 15px;
		line-height: 18px;
		color: #fff;
		white-space: pre-line;
	}
`;

export const EventTextWrapper = styled.div`
	letter-spacing: 3.99px;
	color: #fff;
	font-stretch: normal;
	object-fit: contain;

	h2 {
		font-size: 45px;
		font-weight: bold;
		text-shadow: 0 1px 3px #724f9e;
	}

	p {
		font-size: 30px;
		font-weight: bold;
		text-shadow: 0 1px 3px #724f9e;
	}

	> span {
		display: inline-block;
		margin-top: 20px;
		font-size: 15px;
		font-weight: bold;
		letter-spacing: normal;
		text-shadow: 0 1px 3px #724f9e;
	}

	button {
		width: 16px;
		margin-left: 7px;

		img {
			vertical-align: middle;
		}
	}
`;

export const DownLoadImageWrapper = styled.div`
	position: relative;
	bottom: -270px;

	display: flex;
	flex-direction: column;
	z-index: 6;
	align-items: center;
	> div {
		width: 137px;
		height: 41px;
		&:first-of-type {
			margin-bottom: 12px;
		}
	}
`;

export const SwiperWrapper = styled.div`
	margin-bottom: 13px;

	.swiper-container {
		height: 450px;
		overflow: inherit !important;

		.swiper-pagination {
			width: 100%;
			position: absolute;
			left: 50%;
			z-index: 2;
			transform: translateX(-50%);
			bottom: 0;
			.swiper-pagination-bullet {
				display: inline-block;
				width: 6px;
				height: 6px;
				opacity: 0.3;
				margin: 0 4.5px;
				border-radius: 50%;
				background-color: #fff;
			}

			.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}
	}
`;

export const PlayNowWrapper = styled.div`
	position: absolute;
	width: 375px;
	top: -6px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;

	img {
		width: 100%;
	}
`;
