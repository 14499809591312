import styled from '@emotion/styled';

export const InnerWrap = styled.section`
	width: 1520px;
	height: 100%;
	margin: 0 auto;
	position: relative;
`;

export const ModalInnerWrap = styled.section`
	width: 1520px;
	height: 100%;
	margin: 0 auto;
	position: relative;
`;

export const Section = styled.article`
	width: 100%;
	height: auto;
	min-height: 100vh;
`;

export const SwiperWrapLg = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: #fff;
	.swiper-pagination {
		z-index: 5;
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		.swiper-pagination-bullet {
			display: inline-block;
			width: 30px;
			height: 25px;
			background: url('/images/button/bullet_off.png') no-repeat;
			background-size: 30px 25px;
			cursor: pointer;
			& + .swiper-pagination-bullet {
				margin-left: 16px;
			}
			&.swiper-pagination-bullet-active {
				background: url('/images/button/bullet_on.png') no-repeat;
				background-size: 30px 25px;
			}
		}
	}
`;

export const SwiperWrapLg2 = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: #fff;
	.swiper-pagination {
		z-index: 5;
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		.swiper-pagination-bullet {
			display: inline-block;
			width: 10px;
			height: 10px;
			background: url('/images/button/page_off.png') no-repeat;
			background-size: 9px 10px;
			cursor: pointer;
			& + .swiper-pagination-bullet {
				margin-left: 16px;
			}
			&.swiper-pagination-bullet-active {
				background: url('/images/button/page_on.png') no-repeat;
				background-size: 10px 10px;
			}
		}
	}
`;

export const MobileSection = styled.article<{ imageUrl: string }>`
	padding: 30px 0 70px 0;
	background: url(${(props) => props.imageUrl}) 50% 50% repeat;
	background-size: cover;
	text-align: center;
`;

export const SectionInner = styled.div`
	padding: 35px 0 70px 0;
`;

export const NoWrap = styled.span`
	white-space: nowrap;
`;

export const LightFont = styled.p`
	font-weight: lighter;
`;
