import styled from '@emotion/styled';

export const Wrapper = styled.section`
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const Button = styled.button<{ isOpen: boolean }>`
	display: block;
	width: 100%;
	background: ${(props) => (props.isOpen ? '#555555' : '##3c3c3c')};
	padding: 13px;
	font-weight: bold;
	text-align: left;
	color: #fff;
	font-size: 20px;
	transition: background 0.2s;

	&:hover {
		background: #555555;
	}

	div {
		width: 1450px;
		margin: 0 auto;
	}
`;

export const Arrow = styled.span<{ isOpen: boolean }>`
	margin-left: 10px;
	transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
	transition: transform 0.2s;
	vertical-align: text-top;
	font-size: 23px;
`;

export const Contents = styled.div`
	height: 0;
	background: #555555;
	overflow: hidden;
	transition: height 0.2s ease;

	> div {
		width: 1450px;
		margin: 0 auto;
		padding: 30px 20px;
		font-size: 20px;
		font-weight: 100;
		line-height: 1.5;
	}
`;
