import styled from '@emotion/styled';

export const Wrapper = styled.footer`
	padding: 20px 0 25px 0;
	text-align: center;
	background-color: #790d99;
`;

export const LogoWrap = styled.div`
	margin-bottom: 11px;
`;

export const SnsLinkLabel = styled.strong`
	font-size: 10px;
	color: #fff;
`;

export const SnsLinkWrap = styled.div`
	margin-top: 10px;
`;

export const Copyright = styled.p`
	font-size: 5px;
	font-weight: 100;
	color: #fff;
`;
