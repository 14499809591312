import React from 'react';

import ScrollIntoView from 'react-scroll-into-view';

import {
	NavLink,
	NavWrap,
	Wrapper,
	LogoWrap,
	CloseBtn,
	MarketplaceNavLink,
	GachaButtonM,
} from './Menu.styles';

import { anchors } from '@/components/containers/FullpageWeb';

interface IProps {
	visible: boolean;
	onClickClose: () => void;
	onClickMenu: () => void;
}

const Menu: React.VFC<IProps> = ({ visible, onClickClose, onClickMenu }) => {
	return (
		<Wrapper visible={visible}>
			<LogoWrap>
				<img
					src="/images/common/logo_w.png"
					alt="Metalivly"
					width={187}
					height={45}
				/>
			</LogoWrap>
			<CloseBtn onClick={onClickClose} aria-label="menu-close-button">
				<img src="/images/button/btn_close.png" alt="" width={23} height={21} />
			</CloseBtn>

			<NavWrap>
				{anchors.map((anchor, index) => {
					return (
						<NavLink key={index}>
							<ScrollIntoView selector={`#scroll-${anchor.toLowerCase()}`}>
								<button onClick={onClickMenu}>
									{anchor.replaceAll('_', ' ')}
								</button>
							</ScrollIntoView>
						</NavLink>
					);
				})}
				<NavLink>
					<a href="https://cocone.app/dxn" target="_blank" rel="noreferrer">
						Whitepaper
					</a>
				</NavLink>
				<MarketplaceNavLink>
					<a href="https://cocone.app/dxC" target="_blank" rel="noreferrer">
						Marketplace
					</a>
				</MarketplaceNavLink>
				<GachaButtonM>
					<a
						href="https://www.metalivly.com/gacha-web3"
						target="_blank"
						rel="noreferrer"
					>
						Gacha Web3.0
					</a>
				</GachaButtonM>
			</NavWrap>
		</Wrapper>
	);
};

export default Menu;
