import React from 'react';

import Table from './RewardTable';
import Accordion from './Accordion';
import { RewardDataSlice } from './RewardSlice';
import {
	Main,
	Wrapper,
	Title,
	ExplanationList,
	TableWrapper,
} from './Overview.styles';

import Header from '@/gachaWeb/common/Header/Header';
import Footer from '@/gachaWeb/containers/Overview/Footer';

const Overview: React.VFC = () => {
	return (
		<Main>
			<Header />
			<Wrapper>
				<Title>
					<h2>Overview</h2>
					<div className="subTitleText">
						<p>
							Duplicate outfits earned by the players can be submitted to the
							Digital Data Lab for research to combine and form new hom, island,
							and livly fashion outfits.
							<br /> Players can gain points based on the scarcity of the
							outfits submitted to the Data Lab and will be ranked according to
							the combined player fashion score.
						</p>
						&nbsp;
						<p>
							Players in the top ranks in each round operated every 24 hours
							will receive $SD and GP in return for their research
							contributions.
							<br />
							In other words, players who collect rarer outfits can earn higher
							profits. The total amount of $SD and GP rewards from the Digital
							Data Research
							<br />
							will be determined by the rewards carried over and the amount
							spent last week on gacha.
						</p>
					</div>
				</Title>
				<Accordion title="Explanation on the outfit score calculation">
					<div>
						<p>
							The outfit score submitted by the player is calculated every hour.
							<br />
							The outfit score is the total sum of the item scores that make up
							the outfits and is rounded down to 2 decimal places.
							<br />
							The item score can differ based on rarity, theme bonus, and wish
							bonus.
						</p>
						<br />
						<p className="text-color-mint">
							Allocated rarity points 60% New Theme Bonus 30% Wish Bonus 10%
							&#40;Based on maximum value&#41;
						</p>
						&nbsp;
						<ExplanationList>
							<li>
								<h3>Rarity</h3>
								<p className="indent-text">
									Each rarity grade is given a fixed allocated point and the
									fixed allocated point is shared based on the total number of
									items submitted for staking.
									<br />
									In other words, the higher the item &#39;s grade and the rarer
									it is, the higher the item score.
								</p>
								&nbsp;
								<TableWrapper className="indent-text">
									<tbody>
										<tr>
											<td className="title-cell">SR</td>
											<td>2% Fixed</td>
											<td>Total 0% ~ 6%</td>
										</tr>
										<tr>
											<td className="title-cell">R</td>
											<td>2.5%~3.5%</td>
											<td>Total 9%~19%</td>
										</tr>
										<tr>
											<td className="title-cell">N</td>
											<td>3.1%~5.3%</td>
											<td>Total 79%~89%</td>
										</tr>
									</tbody>
								</TableWrapper>
							</li>
							&nbsp;
							<li>
								<h3>Wish Bonus</h3>
								<p className="indent-text">
									The item players want in Meta Livly can be marked as a wish
									and depending on the number of wishes, additional
									magnification is applied to the item score.
									<br />
									0.0001 times is multiplied per wish for a maximum possibility
									of 0.15 times additional magnification.
									<br />
									However, the maximum efficiency of the wish count is 1,500
									times and the point beyond that is treated equally.
									<br />
									The calculation for additional wish magnification is
									calculated at the end of the round for the previous day.
								</p>
							</li>
							&nbsp;
							<li>
								<h3>Theme Bonus Point</h3>
								<p className="indent-text">
									The Digital Data Lab selects the Featured Theme to be
									researched every week.
									<br />
									An item score of 1.5 times is applied to the Featured Theme
									&#39;s item.
									<br />
									The research period for the Featured Theme is 7 days.
								</p>
								&nbsp;
								<p className="indent-text">
									The total score is the sum of the outfit score submitted by
									the player and is the combined score of each outfit score
									rounded down to 2 decimal places.
									<br />
									The rank and reward are given out every 24 hours based on the
									player &#39;s total score.
								</p>
							</li>
						</ExplanationList>
					</div>
				</Accordion>
				<Accordion title="Explanation on how the reward will be given">
					<div className="accordionGiven">
						<p>
							Rewards will be given up to 300th place according to the reward
							pool based on the reward tier.
							<br />
							Participants with the same score will receive the proper rewards
							equally corresponding to their tier and the following order will
							be skipped according to the number of people.
							<br />
							However, the minimum and maximum rewards for each rank are fixed.
							<br />
							Please refer to the Reward Pool Configuration Table for the
							distribution rate of each ranking.
							<br />
							If there are less than 300 participants, any unclaimed rewards in
							the lower ranks are carried over and added to the reward pool the
							next day.
						</p>
					</div>
				</Accordion>
				<Accordion title="Explanation on the reward pool configuration">
					<div className="accordionPool">
						<p>
							The total reward of $SD and GP rewarded by the Digital Data
							Research is accumulated from last week&#39;s gacha sales.
							<br />
							The Digital Data Research reward pool is determined depending on
							the reward rate after the pre-incineration amount of each SD and
							GP aggregated sales is applied.
						</p>
						&nbsp;
						<ul>
							<li>
								<span className="text-color-mint">- SD Reward Pool</span>: All
								CC spent on Gacha, excluding free amount and platform commission
								is converted to $SD and added to the total amount of $SD spent
								on Gacha.
								<br />
								<p id="sdRewardExplane">
									Then it is configured to be included in the reward, plus any
									rewards carried over.
								</p>
							</li>
							<li>
								<span className="text-color-mint">- GP Reward Pool</span>: GP
								spent on Gacha is configured only from newly generated ones
								rewarded through play, excluding the Digital Data Research
								reward/mining reward.
							</li>
						</ul>
						&nbsp;
						<p>GP rewards will not be carried over and will be incinerated.</p>
					</div>
				</Accordion>
				<Accordion title="Reward pool configuration formula">
					<div>
						<p className="text-color-mint">
							Total Digital Data Research Reward Pool &#91;P&#93; =<br /> Last
							week&#39;s gacha sales * &#40;1 - pre-inceneration rate
							&#40;0.1&#41;&#41; * Digital Data Research reward rate
							&#40;1.0&#41;
						</p>
					</div>
				</Accordion>
				<Accordion title="Explanation on the rewards distribution method for each rank">
					<div>
						<p>
							The reward for each rank will be determined by multiplying the
							total Digital Data Research reward pool &#91;P&#93; by each
							ranking&#39;s corresponding distribution rate.
							<br />
							Please refer to the table below for the distribution rate for each
							rank <br />
							* SD will be given out up to 3 decimal places, the 4th will be
							discarded.
							<br />* GP will be given out in whole numbers and any decimal
							numbers will be discarded.
						</p>
					</div>
				</Accordion>
				<Accordion title="Reward distribution formula for each rank">
					<div>
						<p className="text-color-mint">
							Rewards for the Corresponding Ranking &#91;Q&#93; = P *
							Distribution Rate
						</p>
					</div>
				</Accordion>
				<Accordion title="Reward pool configuration table">
					<Table tableData={RewardDataSlice} />
					<p className="table-explanation">
						The reward table will differ each round, so please check the
						Dashboard for the reward and ranking.
					</p>
				</Accordion>
			</Wrapper>
			<Footer />
		</Main>
	);
};

export default Overview;
