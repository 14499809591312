import styled from '@emotion/styled';

export const Wrapper = styled.div<{ visible: boolean }>`
	padding: 35px 0;
	background-color: #790d99;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	text-align: center;
	transform: translateX(100%);
	transition: all 0.5s ease-in;
	pointer-events: none;
	visibility: hidden;
	z-index: 19999;

	${(props) =>
		props.visible &&
		`
		transform: translateX(0);
		pointer-events: auto;
		visibility: visible;
	`}
`;

export const CloseBtn = styled.button`
	position: absolute;
	top: 20px;
	right: 35px;
`;

export const LogoWrap = styled.div`
	margin-bottom: 66px;
`;

export const NavWrap = styled.ul`
	font-size: 22px;
`;

export const NavLink = styled.li`
	margin-bottom: 22px;

	button,
	a {
		font-weight: bold;
		color: #fff;
	}
`;

export const GachaLink = styled.li`
	display: inline-block;
	font-weight: bold;
	font-size: 22px;
	color: #fff;
`;

export const MarketplaceNavLink = styled(NavLink)`
	width: 135px;
	margin: 28px auto auto auto;
	padding: 20px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.4);
`;

export const GachaButtonM = styled(NavLink)`
	width: 170px;
	margin: 0px auto auto auto;
`;
