import styled from '@emotion/styled';

export const Wrapper = styled.footer`
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #000;
	color: #fff;

	section {
		display: flex;
		width: 1450px;
		margin: 0 auto;
		padding: 33px 0;
	}
`;

export const DashboardButton = styled.div`
	margin-right: 24px;

	div {
		padding: 14px 70px;
		background: #7dccba;
		font-size: 28px;

		span {
			margin-left: 5px;
			vertical-align: top;
			font-size: 30px;
		}
	}
`;

export const Explanation = styled.div`
	font-size: 13px;
	line-height: 1.54;
`;
