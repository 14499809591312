import styled from '@emotion/styled';

export const Wrapper = styled.div`
	text-align: center;
	h2 {
		margin-bottom: 29px;
		font-size: 30px;
	}
	div {
		width: 246px;
		margin: 31px auto;
	}

	p {
		line-height: 1.5;
		font-weight: 300;
		font-size: 20px;
	}
`;
