import React from 'react';

// import { useRecoilState } from 'recoil';

import {
	SnsLinkWrap,
	SnsAnchor,
	SnsImg,
	// BubbleBlock
} from './SnsLink.styles';

// import { displayBubbleState /** actionTimeState **/ } from '@/state/linkState';

const SnsLink: React.VFC = () => {
	// const [displayBubble /** setDisplayBubble**/] =
	// 	useRecoilState(displayBubbleState);
	// const [actionTime] = useRecoilState(actionTimeState);

	// if (!displayBubble) {
	// 	if (new Date().getTime() - actionTime >= 3000) {
	// 		setDisplayBubble(true);
	// 	}
	// }

	return (
		<SnsLinkWrap>
			{/* {displayBubble && (
				<BubbleBlock>
					<img
						src="/images/common/bubble.png"
						className="bubble"
						width={171.2}
						height={35.6}
						alt="metalivly"
					/>

					<p className="text">Ongoing Events</p>
				</BubbleBlock>
			)} */}
			<div>
				<li>
					<SnsAnchor
						href="https://linktr.ee/metalivly"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_linktree.png"
							width={56}
							height={56}
							alt="linktree"
						/>
					</SnsAnchor>
				</li>
				<li>
					<SnsAnchor
						href="https://cocone.app/dHQ"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_discord.png"
							width={56}
							height={56}
							alt="discord"
						/>
					</SnsAnchor>
				</li>
				<li>
					<SnsAnchor
						href="https://cocone.app/dHs"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_twitter.png"
							width={56}
							height={56}
							alt="twitter"
						/>
					</SnsAnchor>
				</li>
				<li>
					<SnsAnchor
						href="https://cocone.app/dHD"
						target="_blank"
						rel="noreferrer"
					>
						<SnsImg
							src="/images/button/btn_medium.png"
							width={56}
							height={56}
							alt="medium"
						/>
					</SnsAnchor>
				</li>
			</div>
		</SnsLinkWrap>
	);
};

export default SnsLink;
