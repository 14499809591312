import React from 'react';

import { FooterWrap, Inner, Image, Copyright } from './Footer.styles';

const Footer: React.VFC = () => {
	return (
		<FooterWrap className="section fp-auto-height">
			<Inner>
				<a href="#Home">
					<Image src="/images/common/logo_footer.png" alt="Meta Livly" />
					<Copyright>
						©2022. Cocone M Corporation ©2021. cocone corporation. All Rights
						Reserved.
					</Copyright>
				</a>
			</Inner>
		</FooterWrap>
	);
};

export default Footer;
